import { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";

interface CustomerLoginProps {
  username: string;
  password: string;
  [key: string]: any;
}

export const useAuthentication = () => {
  const [isCustomerCreated, setIsCustomerCreated] = useState(false);
  const [customerLoginData, setCustomerLoginData] =
    useState<CustomerLoginProps | null>(null);
  const [dialogMessage, setDialogMessage] = useState("");
  const navigate = useNavigate();

  const handleAuthSuccess = useCallback(
    (data: any) => {
      console.log("Authentication successful", data);
      setDialogMessage("Authentication successful. Redirecting...");

      const currentPath = window.location.pathname;
      const hostname = window.location.hostname;
      const isLocalhost = hostname === "localhost" || hostname === "127.0.0.1";

      // Get all segments except the last one
      const pathParts = currentPath.split("/").filter(Boolean);
      const productType = pathParts[0]; //'broadband' / 'wifi'/ private-services/ selfcare for localhost
      const venueId = isLocalhost ? pathParts[1] : pathParts[0]; //mccpen' or 'lax'

      if (productType && venueId) {
        const newPath = isLocalhost
          ? `/${productType}/${venueId}/welcome`
          : `/${venueId}/welcome`;

        window.location.href = isLocalhost
          ? `${window.location.origin}${newPath}`
          : newPath;
      }
    },
    [navigate]
  );

  const handleAuthError = useCallback((error: any) => {
    console.error("Authentication failed", error);
    let errorMessage = "Authentication failed";

    if (error.includes("Missing required fields:")) {
      errorMessage = `${error}. User was created, please contact support.`;
    } else {
      errorMessage = `${error}. Please try again.`;
    }

    setDialogMessage(errorMessage);
  }, []);

  const initiateAuthentication = useCallback(
    (loginData: CustomerLoginProps) => {
      setCustomerLoginData(loginData);
      setIsCustomerCreated(true);
    },
    []
  );

  return {
    isCustomerCreated,
    customerLoginData,
    dialogMessage,
    handleAuthSuccess,
    handleAuthError,
    initiateAuthentication,
    setDialogMessage,
  };
};
