/**
 * Converts a number to a string with 2 decimal places.
 * @param {number} num - The number to convert.
 * @returns {string} The number formatted as a string with 2 decimal places.
 */
export const formatToTwoDecimals = (num: number) => {
  if (typeof num !== "number") {
    console.warn("formatToTwoDecimals: Input is not a number");
    return "0.00";
  }
  return num.toFixed(2);
};

/**
 * Converts a UTC date string to a regular date format (MM/DD/YYYY).
 * @param {string} utcDate - The UTC date string to convert.
 * @returns {string} The date in MM/DD/YYYY format.
 */
export const formatUTCToMMDDYYYY = (dateString: string): string => {
  const date = new Date(dateString);

  const month = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are zero-indexed
  const day = String(date.getUTCDate()).padStart(2, "0");
  const year = date.getUTCFullYear();

  return `${month}/${day}/${year}`;
};

export const formatUTCDateTime = (dateString: string): string => {
  const date = new Date(dateString);

  const options: Intl.DateTimeFormatOptions = {
    month: "2-digit",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
    timeZone: "UTC",
  };

  return date.toLocaleString("en-US", options);
};

export const formatMacAddressWithColons = (macAddress: string): string => {
  // Remove any non-alphanumeric characters first
  const cleanMac = macAddress.replace(/[^A-Fa-f0-9]/g, "");

  // Limit to 12 characters (a valid MAC address length)
  const truncatedMac = cleanMac.slice(0, 12);

  // Insert colons after every 2 characters, except for the last group
  const formattedMac = truncatedMac.match(/.{1,2}/g)?.join(":") || truncatedMac;

  return formattedMac.toUpperCase();
};

/**
 * Finds the discounted product in an array of  products.
 * @param {array} products - The array of internet products.
 * @returns {object} The discounted product object.
 */

export const formatDeviceName = (name: string): string => {
  // Allow letters, numbers, hyphens, underscores, and spaces
  return name.replace(/[^a-zA-Z0-9\-_\s]/g, "");
};

interface FormattedProduct {
  name: string;
  price: number;
  quantity?: number;
}

export const convertProductCodesToReadableNames = (
  productCodes: string[]
): FormattedProduct[] => {
  const products: FormattedProduct[] = [];
  const additionalDeviceCount = productCodes.filter((code) =>
    code.includes("DL_Broadband_Additional_Device_[1_Month_Recurring]")
  ).length;

  // Process internet plans first
  const internetPlan = productCodes.find((code) => {
    return (
      code.includes("Broadband_Internet") ||
      code.includes("_Broadband_Internet_")
    );
  });

  if (internetPlan) {
    const priceMatch = internetPlan.match(/\d+\.\d+(?=\])/);
    const price = priceMatch ? parseFloat(priceMatch[0]) : 0;
    const duration = extractDurationFromProductCode(internetPlan);

    let planName = "";
    if (internetPlan.includes("Blazing")) {
      planName = "Blazing Internet";
    } else if (internetPlan.includes("Extreme")) {
      planName = "Extreme Internet";
    } else if (internetPlan.includes("Extra")) {
      planName = "Extra Internet";
    }

    if (planName) {
      products.push({
        name: `${planName} (${duration})`,
        price: price,
      });
    }
  }

  // Process additional devices
  if (additionalDeviceCount > 0) {
    products.push({
      name: `${additionalDeviceCount} Additional Device${
        additionalDeviceCount > 1 ? "s" : ""
      } (Monthly)`,
      price: additionalDeviceCount * 10.0,
      quantity: additionalDeviceCount,
    });
  }

  return products;
};

/**
 * Extracts the duration value from a product code string.
 * @param {string} productCode - The product code string to extract duration from.
 * @returns {string} The duration value (Day, Week, Month, or Monthly).
 */
export const extractDurationFromProductCode = (productCode: string): string => {
  // Match pattern between [1_ and either _ or ] including cases with Recurring and price
  const match = productCode.match(
    /\[1_(Day|Week|Month|Monthly)(?:_Recurring(?:_\d+\.\d+)?|\]|_\d+\.\d+\])/
  );

  if (!match) return "";

  const duration = match[1];
  const hasRecurring = productCode.includes("Recurring");

  // If it has Recurring and duration is Month, return Monthly
  if (hasRecurring && duration.toLowerCase() === "month") {
    return "Monthly";
  }

  // For all other cases, return "1" + duration
  return `1 ${duration.charAt(0).toUpperCase() + duration.slice(1)}`;
};

/**
 * Appends duration from product code to action label
 * @param {string} label - The original action label
 * @param {string} productCode - The product code to extract duration from
 * @returns {string} The label with appended duration if applicable
 */
export const appendDurationToLabel = (
  label: string,
  productCode?: string
): string => {
  if (!productCode) return label;

  const duration = extractDurationFromProductCode(productCode);

  // Only append for "Add Another" action
  if (label === "Add Another" && duration) {
    return `${label} ${duration}`;
  }

  return label;
};

/**
 * Determines message content from messeage-center.const.ts based on product code and message data
 * @param {string | undefined} internetProductCode - The internet product code
 * @param {object | undefined} messageData - The message data object
 * @param {Record<string, any>} messagesIndex - The messages index object
 * @returns {object | null} The appropriate message content or null
 */
export const determineMessageContent = (
  internetProductCode?: string,
  messageData?: { code: string },
  messagesIndex?: Record<string, any>
): any => {
  if (!messagesIndex) return null;

  if (messageData && messageData.code !== "NONE") {
    if (internetProductCode?.includes("AETC_Free")) {
      return messagesIndex["FREE_RESTRICTED_AETC"];
    }
    return messagesIndex[messageData.code];
  }

  if (internetProductCode?.includes("MCCS_50_Mbps_Free_Internet")) {
    return messagesIndex["FREE_RESTRICTED_USMC"];
  }

  return null;
};

export const resetCancelFlowState = () => {
  localStorage.removeItem('cancelData')
}