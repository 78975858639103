import React, { useState, useEffect } from "react";
import { Box, Typography, Paper, TextField, Button } from "@mui/material";

interface Product {
  plan_name: string;
  product_code: string;
  speed?: string | undefined | null;
  speed_unit?: string | undefined | null;
  original_price?: string | undefined | null;
  discount?: {
    discounted_price?: string | undefined | null;
    amount_off?: string | undefined | null;
    discount_duration?: string | undefined | null;
    promo_code?: string | undefined | null;
  };
  additional_devices?: Device[] | undefined | null;
  selected_duration?: string;
  isAetcDeal?: boolean;
}

interface Device {
  device_name?: string;
  device_detail_name?: string;
  device_price?: string;
  product_code?: string;
}

interface TableSummaryProps {
  data: any;
  selectedProduct: Product;
  isCheckoutPage?: boolean;
  onTotalPriceChange?: (price: number) => void;
}

const TableSummary: React.FC<TableSummaryProps> = ({
  data,
  selectedProduct,
  isCheckoutPage = false,
  onTotalPriceChange,
}) => {
  const [promoCode, setPromoCode] = useState("");
  const [isPromoApplied, setIsPromoApplied] = useState(false);

  useEffect(() => {
    // Set initial promo code if it exists in the selectedProduct
    if (selectedProduct.discount && selectedProduct.discount.promo_code) {
      setPromoCode(selectedProduct.discount.promo_code);
      setIsPromoApplied(true);
    } else {
      setIsPromoApplied(false);
    }
  }, [selectedProduct]);

  const isMonthlyPlan =
    !selectedProduct.selected_duration ||
    selectedProduct.selected_duration === "monthly";

  const isFreePlan = selectedProduct.plan_name.toLowerCase() === "free";

  const hasDiscount =
    selectedProduct.discount && selectedProduct.discount.amount_off;

  const originalPrice = parseFloat(selectedProduct?.original_price || "0");
  const discountAmount = hasDiscount
    ? parseFloat(selectedProduct?.discount?.amount_off || "0")
    : 0;
  const discountedPrice = hasDiscount
    ? originalPrice - discountAmount
    : originalPrice;

  const additionalDevice = selectedProduct.additional_devices?.[0];
  const additionalDevicePrice = additionalDevice
    ? parseFloat(additionalDevice?.device_price || "0")
    : 0;

  const totalPrice =
    (isMonthlyPlan ? discountedPrice : originalPrice) + additionalDevicePrice;

  useEffect(() => {
    onTotalPriceChange?.(totalPrice);
  }, [totalPrice, onTotalPriceChange]);

  const handlePromoCodeChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newPromoCode = event.target.value;
    setPromoCode(newPromoCode);
    setIsPromoApplied(false);
  };

  const handleApplyPromoCode = () => {
    console.log("Applying promo code:", promoCode);
    setIsPromoApplied(true);
  };

  if (selectedProduct.isAetcDeal) {
    return null; // Return null to hide the entire component if isAetcDeal is true
  }

  return (
    <>
      {/* <pre>{JSON.stringify(selectedProduct, null, 2)}</pre> */}
      <Paper
        elevation={0}
        data-test-id="order-summary-container"
        sx={{
          padding: "20px",
          marginBottom: "20px",
          backgroundColor: "#fff",
          border: "1px solid #e0e0e0",
        }}
      >
        <Typography
          variant="body2"
          gutterBottom
          data-test-id="order-summary-title"
          sx={{ fontFamily: "Figtree-Bold", fontSize: "16px" }}
        >
          Order Summary
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
          }}
          data-test-id="plan-details"
        >
          <Typography data-test-id="plan-name">
            {selectedProduct.plan_name} - up to {selectedProduct.speed}{" "}
            {selectedProduct.speed_unit}
          </Typography>
          <Typography data-test-id="plan-price">
            ${originalPrice.toFixed(2)}
          </Typography>
        </Box>
        {hasDiscount && isMonthlyPlan && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
            data-test-id="discount-details"
          >
            <Typography>Discount</Typography>
            <Typography data-test-id="discount-amount" color="error">
              -${discountAmount.toFixed(2)}
            </Typography>
          </Box>
        )}

        {additionalDevice && (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
            data-test-id="additional-device-details"
          >
            <Typography data-test-id="device-name">
              {additionalDevice.device_detail_name}
            </Typography>
            <Typography data-test-id="device-price">
              ${additionalDevicePrice.toFixed(2)}
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "bold",
            borderTop: "1px solid #e0e0e0",
            paddingTop: 2,
            marginTop: 2,
          }}
          data-test-id="total-price-container"
        >
          <Typography sx={{ fontFamily: "Figtree-Bold" }}>
            Total Price
          </Typography>
          <Typography
            data-test-id="total-price"
            sx={{ fontFamily: "Figtree-Bold" }}
          >
            ${totalPrice.toFixed(2)}
          </Typography>
        </Box>
      </Paper>

      {isCheckoutPage && isMonthlyPlan && !isFreePlan && (
        <Box
          className="promo-code-container"
          data-test-id="promo-code-section"
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            marginTop: "20px",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", width: "100%" }}>
            <TextField
              value={promoCode}
              onChange={handlePromoCodeChange}
              label="Enter Discount Code"
              variant="outlined"
              disabled={isPromoApplied}
              data-test-id="promo-code-input"
              sx={{
                marginRight: "10px",
                flexGrow: 1,
                backgroundColor: isPromoApplied ? "#eee" : "#fff",
              }}
            />
            <Button
              disableElevation
              variant="contained"
              onClick={handleApplyPromoCode}
              disabled={isPromoApplied}
              data-test-id="apply-promo-button"
              sx={{
                height: "55px",
                backgroundColor: isPromoApplied ? "#ccc" : "#111",
                color: "white",
                "&:hover": {
                  backgroundColor: isPromoApplied ? "#ccc" : "#9a0007",
                },
              }}
            >
              {isPromoApplied ? "Applied" : "Apply"}
            </Button>
          </Box>
          {isPromoApplied && (
            <Typography
              sx={{
                color: "primary.main",
                marginTop: "10px",
                fontSize: "13px",
              }}
            >
              Nice! Enjoy your discount
            </Typography>
          )}
        </Box>
      )}
    </>
  );
};

export default TableSummary;
