import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { useCustomerData } from "../../hooks/useApiData";
import CurrentPaymentMethodForm from "../../SelfcareComponents/CurrentPaymentMethodForm";

interface ChangePlanSelectedProductsProps {
  selectedProducts: any;
}

const ChangePlanPaymentInfo: React.FC<ChangePlanSelectedProductsProps> = ({
  selectedProducts,
}) => {
  const { isLoading } = useCustomerData();

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <CurrentPaymentMethodForm
        selectedProducts={selectedProducts}
        showActionButtons={true}
      />
    </Box>
  );
};

export default ChangePlanPaymentInfo;
