import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import braintree from "braintree-web";
import {
  Box,
  FormControl,
  InputLabel,
  FormHelperText,
  Typography,
  Select,
  MenuItem,
} from "@mui/material";
import { Controller, Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BRAINTREE_AUTH_TOKEN } from "../../config/configs";
import styles from "./BraintreeHostedFieldsForm.module.css";
import { COUNTRIES } from "../../assets/const/countries.const";

interface BraintreeHostedFieldsFormProps {
  control?: Control<any>;
  onValidityChange: (isValid: boolean) => void;
}

export interface BraintreeHostedFieldsFormRef {
  tokenize: () => Promise<{
    payload: braintree.HostedFieldsTokenizePayload;
    deviceData: string;
  }>;
  clearFields: () => void;
}

const BraintreeHostedFieldsForm = forwardRef<
  BraintreeHostedFieldsFormRef,
  BraintreeHostedFieldsFormProps
>(({ control, onValidityChange }, ref) => {
  const { t } = useTranslation();
  const [hostedFieldsInstance, setHostedFieldsInstance] =
    useState<braintree.HostedFields | null>(null);
  const [dataCollectorInstance, setDataCollectorInstance] =
    useState<braintree.DataCollector | null>(null);
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isValid, setIsValid] = useState(false);
  const [fieldStates, setFieldStates] = useState({
    number: { isFocused: false, isEmpty: true },
    expirationDate: { isFocused: false, isEmpty: true },
    cvv: { isFocused: false, isEmpty: true },
    postalCode: { isFocused: false, isEmpty: true },
  });

  useImperativeHandle(ref, () => ({
    tokenize: async () => {
      if (!hostedFieldsInstance || !isValid) {
        throw new Error("Hosted fields are not valid or not initialized");
      }

      try {
        const payload = await hostedFieldsInstance.tokenize();
        let deviceData = "";

        try {
          if (dataCollectorInstance && dataCollectorInstance.deviceData) {
            deviceData = dataCollectorInstance.deviceData;
          }
        } catch (deviceDataError) {
          console.warn("Could not get device data:", deviceDataError);
        }

        return { payload, deviceData };
      } catch (error) {
        console.error("Error tokenizing payment method:", error);
        throw error;
      }
    },
    clearFields: () => {
      if (hostedFieldsInstance) {
        hostedFieldsInstance.clear("number");
        hostedFieldsInstance.clear("expirationDate");
        hostedFieldsInstance.clear("cvv");
        hostedFieldsInstance.clear("postalCode");
        setFieldStates({
          number: { isFocused: false, isEmpty: true },
          expirationDate: { isFocused: false, isEmpty: true },
          cvv: { isFocused: false, isEmpty: true },
          postalCode: { isFocused: false, isEmpty: true },
        });
        setErrors({});
        setIsValid(false);
        onValidityChange(false);
      }
    },
  }));

  useEffect(() => {
    const initializeBraintree = async () => {
      try {
        const clientToken = `${BRAINTREE_AUTH_TOKEN}`;

        if (!clientToken) {
          console.error("No client token available");
          return;
        }

        // Create client instance and wait for it to be ready
        const clientInstance = await braintree.client.create({
          authorization: clientToken,
        });

        // Ensure client instance is valid before proceeding
        if (!clientInstance) {
          console.error("Failed to create Braintree client instance");
          return;
        }

        // Initialize Data Collector with more specific error handling
        try {
          const dataCollectorConfig = {
            client: clientInstance,
            kount: false, // First try without Kount
          };

          const dataCollector = await braintree.dataCollector.create(
            dataCollectorConfig
          );

          if (dataCollector) {
            console.log("Data Collector instance created successfully");
            setDataCollectorInstance(dataCollector);

            // After basic collector works, try enabling Kount
            try {
              const kountCollector = await braintree.dataCollector.create({
                client: clientInstance,
                kount: true,
              });

              if (kountCollector) {
                console.log(
                  "Kount Data Collector instance created successfully"
                );
                setDataCollectorInstance(kountCollector);
              }
            } catch (kountError: any) {
              console.warn("Kount collection disabled:", kountError.message);
              // Keep using the basic collector that was already created
            }
          }
        } catch (dataCollectorError: any) {
          console.error("Data Collector Error:", {
            message: dataCollectorError.message,
            code: dataCollectorError.code,
            type: dataCollectorError.type,
            details: dataCollectorError.details,
          });

          // Continue without Data Collector
          console.warn("Continuing without Data Collector");
        }

        const hostedFieldsInstance = await braintree.hostedFields.create({
          client: clientInstance,
          styles: {
            input: {
              "font-family": "Figtree-Regular, sans-serif, Helvetica, Verdana",
              color: "#9c9c9c",
              "font-size": "15px !important",
              "max-height": "100px",
            },
            "input.invalid": {
              color: "#d52b1e",
            },
            "input.valid": {
              color: "#333",
            },
          },
          fields: {
            number: {
              selector: "#card-number",
            },
            cvv: {
              selector: "#cvv",
            },
            expirationDate: {
              selector: "#expiration-date",
            },
            postalCode: {
              selector: "#postal-code",
            },
          },
        });

        setHostedFieldsInstance(hostedFieldsInstance);

        hostedFieldsInstance.on("validityChange", (event) => {
          const field = event.fields[event.emittedBy];
          const newErrors = { ...errors };

          if (field.isValid) {
            delete newErrors[event.emittedBy];
          } else if (field.isPotentiallyValid) {
            delete newErrors[event.emittedBy];
          } else {
            newErrors[event.emittedBy] = `Invalid ${event.emittedBy}`;
          }

          setErrors(newErrors);
          const formIsValid = Object.keys(event.fields).every(
            (key) => event.fields[key as keyof typeof event.fields].isValid
          );
          setIsValid(formIsValid);
          onValidityChange(formIsValid);
        });

        hostedFieldsInstance.on("focus", (event) => {
          setFieldStates((prev) => ({
            ...prev,
            [event.emittedBy]: {
              ...prev[event.emittedBy as keyof typeof prev],
              isFocused: true,
            },
          }));
        });

        hostedFieldsInstance.on("blur", (event) => {
          setFieldStates((prev) => ({
            ...prev,
            [event.emittedBy]: {
              ...prev[event.emittedBy as keyof typeof prev],
              isFocused: false,
            },
          }));
        });

        hostedFieldsInstance.on("empty", (event) => {
          setFieldStates((prev) => ({
            ...prev,
            [event.emittedBy]: {
              ...prev[event.emittedBy as keyof typeof prev],
              isEmpty: true,
            },
          }));
        });

        hostedFieldsInstance.on("notEmpty", (event) => {
          setFieldStates((prev) => ({
            ...prev,
            [event.emittedBy]: {
              ...prev[event.emittedBy as keyof typeof prev],
              isEmpty: false,
            },
          }));
        });

        return () => {
          if (hostedFieldsInstance) {
            hostedFieldsInstance.teardown();
          }
          if (dataCollectorInstance) {
            dataCollectorInstance.teardown();
          }
        };
      } catch (error) {
        console.error("Error in Braintree initialization:", error);
      }
    };

    initializeBraintree();

    return () => {
      if (hostedFieldsInstance) {
        hostedFieldsInstance.teardown();
      }
      if (dataCollectorInstance) {
        dataCollectorInstance.teardown();
      }
    };
  }, []);

  return (
    <Box data-test-id="braintree-hosted-fields-container">
      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        data-test-id="card-number-container"
      >
        <InputLabel
          shrink={fieldStates.number.isFocused || !fieldStates.number.isEmpty}
          htmlFor="card-number"
          data-test-id="card-number-label"
        >
          {t("signup_page.card_number", {
            defaultValue: "Card Number",
          })}
        </InputLabel>
        <Box
          id="card-number"
          className={styles.hostedField}
          data-test-id="card-number-field"
        />
        {errors.number && (
          <FormHelperText error data-test-id="card-number-error">
            {errors.number}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        data-test-id="expiration-date-container"
      >
        <InputLabel
          shrink={
            fieldStates.expirationDate.isFocused ||
            !fieldStates.expirationDate.isEmpty
          }
          htmlFor="expiration-date"
          data-test-id="expiration-date-label"
        >
          {t("signup_page.expiration_date", {
            defaultValue: "Expiration Date",
          })}
        </InputLabel>
        <Box
          id="expiration-date"
          className={styles.hostedField}
          data-test-id="expiration-date-field"
        />
        {errors.expirationDate && (
          <FormHelperText error data-test-id="expiration-date-error">
            {errors.expirationDate}
          </FormHelperText>
        )}
      </FormControl>

      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        data-test-id="cvv-container"
      >
        <InputLabel
          shrink={fieldStates.cvv.isFocused || !fieldStates.cvv.isEmpty}
          htmlFor="cvv"
          data-test-id="cvv-label"
        >
          {t("signup_page.cvv", {
            defaultValue: "CVV",
          })}
        </InputLabel>
        <Box id="cvv" className={styles.hostedField} data-test-id="cvv-field" />
        {errors.cvv && (
          <FormHelperText error data-test-id="cvv-error">
            {errors.cvv}
          </FormHelperText>
        )}
      </FormControl>

      <Controller
        name="country_code"
        control={control}
        defaultValue="US"
        rules={{ required: "Country is required" }}
        render={({ field }) => (
          <FormControl
            fullWidth
            margin="dense"
            data-test-id="country-container"
          >
            <InputLabel id="country-select-label" data-test-id="country-label">
              {t("signup_page.country", {
                defaultValue: "Country",
              })}
            </InputLabel>
            <Select
              {...field}
              labelId="country-select-label"
              id="country-select"
              data-test-id="country-select"
              label={t("signup_page.country", {
                defaultValue: "Country",
              })}
            >
              {COUNTRIES.map((country) => (
                <MenuItem
                  key={country.code}
                  value={country.code}
                  data-test-id={`country-option-${country.code.toLowerCase()}`}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />

      <FormControl
        fullWidth
        margin="dense"
        variant="outlined"
        data-test-id="postal-code-container"
      >
        <InputLabel
          shrink={
            fieldStates.postalCode.isFocused || !fieldStates.postalCode.isEmpty
          }
          htmlFor="postal-code"
          data-test-id="postal-code-label"
        >
          {t("signup_page.postal_code", {
            defaultValue: "Postal Code",
          })}
        </InputLabel>
        <Box
          id="postal-code"
          className={styles.hostedField}
          data-test-id="postal-code-field"
        />
        {errors.postalCode && (
          <FormHelperText error data-test-id="postal-code-error">
            {errors.postalCode}
          </FormHelperText>
        )}
      </FormControl>

      {Object.keys(errors).length > 0 && (
        <Typography
          color="error"
          variant="body2"
          sx={{ mt: 2 }}
          data-test-id="form-error-message"
        >
          {t("signup_page.correct_errors")}
        </Typography>
      )}
    </Box>
  );
});

BraintreeHostedFieldsForm.displayName = "BraintreeHostedFieldsForm";

export default BraintreeHostedFieldsForm;
