import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import {
  Control,
  Controller,
  FieldErrors,
  UseFormTrigger,
} from "react-hook-form";
import { preflightUsernameService } from "../../services/apiServices";
import { formatUSPhoneNumber } from "../../utils/phoneNumberFormatter";
import styles from "./AuthFields.module.css";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

/** AuthFields are using in BroadbandBand Signup, WiFi Signup, and Selfcare MyInfo page.
 * @param {Control<any>} control - The control object from react-hook-form.
 * @param {FieldErrors} errors - The errors object from react-hook-form.
 * @param {function} onUsernameBlur - Optional callback function triggered when the username input loses focus.
 * @param {function} trigger - Optional trigger function from react-hook-form.
 * @param {string} defaultValue - Optional default value for the field.
 * @param {function} onChange - Optional callback function triggered when the field value changes.
 */
interface AuthFieldsProps {
  control: Control<any>;
  errors: FieldErrors;
  onUsernameBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  trigger?: UseFormTrigger<any>;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const authFieldsResources = {
  en: {
    translation: {
      signup_page: {
        username: "Username",
        password: "Password",
        create_username: "Create Username",
        create_password: "Create Password",
        first_name: "First Name",
        last_name: "Last Name",
        email: "Email",
        phone_number: "Phone Number",
        new_password: "New Password",
      },
    },
  },
};

if (!i18n.isInitialized) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: authFieldsResources,
      fallbackLng: "en",
      interpolation: {
        escapeValue: false,
      },
    });
}

export const UsernameField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
  onUsernameBlur,
  trigger,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="username"
      control={control}
      defaultValue=""
      rules={{
        required: "Username is required",
        pattern: {
          value: /^[a-z0-9._-]+$/,
          message:
            "Username can only contain lowercase letters, numbers, and . - _ characters",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          label={t("signup_page.create_username", {
            defaultValue: "Create Username",
          })}
          fullWidth
          margin="dense"
          error={!!errors.username}
          helperText={errors.username?.message as string | undefined}
          onBlur={async (e: React.FocusEvent<HTMLInputElement>) => {
            field.onBlur();
            if (trigger) {
              await trigger("username");
            }
            if (onUsernameBlur && e.target.value) {
              onUsernameBlur(e);
            }
          }}
          className={styles.authField}
          data-test-id="username-field"
          inputProps={{
            "data-test-id": "username-input",
          }}
        />
      )}
    />
  );
};

export const PasswordField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      name="password"
      control={control}
      defaultValue=""
      rules={{
        required: "Password is required",
        minLength: {
          value: 6,
          message: "Password must be at least 6-16 characters long",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          type={showPassword ? "text" : "password"}
          label={t("signup_page.create_password", {
            defaultValue: "Create Password",
          })}
          fullWidth
          margin="dense"
          error={!!errors.password}
          helperText={errors.password?.message as string | undefined}
          className={styles.authField}
          data-test-id="password-field"
          inputProps={{
            "data-test-id": "password-input",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  data-test-id="password-visibility-toggle"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const FirstNameField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
  defaultValue = "",
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="first_name"
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: "First name is required",
        pattern: {
          value: /^[a-zA-Z]+$/,
          message: "First name should contain only letters",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          label={t("signup_page.first_name", {
            defaultValue: "First Name",
          })}
          fullWidth
          margin="dense"
          error={!!errors.first_name}
          helperText={errors.first_name?.message as string | undefined}
          className={styles.authField}
          data-test-id="first-name-field"
          inputProps={{
            "data-test-id": "first-name-input",
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e as React.ChangeEvent<HTMLInputElement>);
          }}
        />
      )}
    />
  );
};

export const LastNameField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
  defaultValue = "",
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="last_name"
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: "Last name is required",
        pattern: {
          value: /^[a-zA-Z]+$/,
          message: "Last name should contain only letters",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          label={t("signup_page.last_name", {
            defaultValue: "Last Name",
          })}
          fullWidth
          margin="dense"
          error={!!errors.last_name}
          helperText={errors.last_name?.message as string | undefined}
          className={styles.authField}
          data-test-id="last-name-field"
          inputProps={{
            "data-test-id": "last-name-input",
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e as React.ChangeEvent<HTMLInputElement>);
          }}
        />
      )}
    />
  );
};

export const EmailField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
  defaultValue = "",
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="email"
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: "Email is required",
        pattern: {
          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
          message: "Invalid email address",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          type="email"
          label={t("signup_page.email", {
            defaultValue: "Email",
          })}
          fullWidth
          margin="dense"
          error={!!errors.email}
          helperText={errors.email?.message as string | undefined}
          className={styles.authField}
          data-test-id="email-field"
          inputProps={{
            "data-test-id": "email-input",
          }}
          onChange={(e) => {
            field.onChange(e);
            onChange?.(e as React.ChangeEvent<HTMLInputElement>);
          }}
        />
      )}
    />
  );
};

export const PhoneNumberField: React.FC<{
  control: Control<any>;
  errors: FieldErrors;
  defaultValue?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ control, errors, defaultValue = "", onChange }) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="phone_number"
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: "Phone number is required",
        pattern: {
          value: /\d{3}-\d{3}-\d{4}$/,
          message: "Invalid phone number format",
        },
      }}
      render={({
        field: { onChange: fieldOnChange, value, ...rest },
        fieldState: { error },
      }) => (
        <TextField
          {...rest}
          label={t("signup_page.phone_number", {
            defaultValue: "Phone Number",
          })}
          fullWidth
          margin="normal"
          error={!!error}
          helperText={error ? error.message : ""}
          value={value || ""}
          onChange={(e) => {
            const formattedValue = formatUSPhoneNumber(e.target.value);
            fieldOnChange(formattedValue);
            if (onChange) {
              onChange({
                ...e,
                target: {
                  ...e.target,
                  value: formattedValue,
                  name: "phone_number",
                },
              } as React.ChangeEvent<HTMLInputElement>);
            }
          }}
          inputProps={{
            maxLength: 17,
            "data-test-id": "phone-number-input",
          }}
          className={styles.authField}
          data-test-id="phone-number-field"
        />
      )}
    />
  );
};

export const LoginUsernameField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <Controller
      name="login_username"
      control={control}
      defaultValue=""
      rules={{
        required: "Username is required",
        minLength: {
          value: 6,
          message: "Minimum of 6 characters",
        },
        pattern: {
          value: /^[a-z0-9._-]+$/,
          message:
            "Username can only contain lowercase letters, numbers, and . - _ characters",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          label={t("signup_page.username", {
            defaultValue: "Username",
          })}
          fullWidth
          margin="dense"
          error={!!errors.login_username}
          helperText={errors.login_username?.message as string | undefined}
          className={styles.authField}
          data-test-id="login-username-field"
          inputProps={{
            "data-test-id": "login-username-input",
          }}
        />
      )}
    />
  );
};

export const LoginPasswordField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
}) => {
  const [loginShowPassword, loginSetShowPassword] = useState(false);
  const { t } = useTranslation();

  const handleClickShowPassword = () => {
    loginSetShowPassword(!loginShowPassword);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      name="password"
      control={control}
      defaultValue=""
      rules={{
        required: "Password is required",
        minLength: {
          value: 6,
          message: "Password must be at least 6-16 characters long",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          type={loginShowPassword ? "text" : "password"}
          label={t("signup_page.password", {
            defaultValue: "Password",
          })}
          fullWidth
          margin="dense"
          error={!!errors.password}
          helperText={errors.password?.message as string | undefined}
          className={styles.authField}
          data-test-id="login-password-field"
          inputProps={{
            "data-test-id": "login-password-input",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  data-test-id="login-password-visibility-toggle"
                >
                  {loginShowPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};

export const NewPasswordField: React.FC<AuthFieldsProps> = ({
  control,
  errors,
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const { t } = useTranslation();

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownNewPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Controller
      name="new_password"
      control={control}
      defaultValue=""
      rules={{
        required: "Password is required",
        minLength: {
          value: 6,
          message: "Password must be at least 6-16 characters long",
        },
      }}
      render={({ field }) => (
        <TextField
          {...field}
          type={showNewPassword ? "text" : "password"}
          label={t("signup_page.new_password", {
            defaultValue: "New Password",
          })}
          fullWidth
          margin="dense"
          error={!!errors.new_password}
          helperText={errors.new_password?.message as string | undefined}
          className={styles.authField}
          data-test-id="new-password-field"
          inputProps={{
            "data-test-id": "new-password-input",
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowNewPassword}
                  onMouseDown={handleMouseDownNewPassword}
                  edge="end"
                  data-test-id="new-password-visibility-toggle"
                >
                  {showNewPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  );
};
