import axios from "axios";
import { PORTAL_API_PATH } from "../config/configs";
import { fetchCsrfToken } from "./csurfService";
import { getQueryParam } from "../utils/utils";
import { isExemptPath } from "../utils/exemptPaths";
const apiService = axios.create({
  baseURL: PORTAL_API_PATH,
  withCredentials: true,
  //enable for https localhost testing for apple pay, do: npm run https for https localhost testing
});

// Add a request interceptor
apiService.interceptors.request.use(
  async (config) => {
    const method = config.method?.toUpperCase();

    // bypass CSRF token check for POST, PUT, PATCH, DELETE requests in isExemptPath
    if (
      ["POST", "PUT", "PATCH", "DELETE"].includes(method as string) &&
      !isExemptPath(config.url as string)
    ) {
      const csrfToken = await fetchCsrfToken();
      config.headers["X-CSRF-Token"] = csrfToken;
    }
    config.headers["GSID"] = getQueryParam("gsid");
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor for any errors
apiService.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      console.error("Error response:", {
        status: error.response.status,
        data: error.response.data,
      });
    } else {
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);

export default apiService;
