import { useEffect, forwardRef, useImperativeHandle, useRef } from "react";
import braintree, { PayPalCheckout } from "braintree-web";
import { BRAINTREE_AUTH_TOKEN } from "../../config/configs";
import { MERCHANT_NAME_CONSTANT } from "../../assets/const/merchant-name.const";

declare global {
  interface Window {
    paypal: any;
  }
}

interface PaypalFormProps {
  onSuccess: (nonce: string, deviceData: string, details: any) => void;
  onError?: (error: Error) => void;
  amount: number;
  merchantName?: string;
}

export interface PaypalFormRef {
  tokenize: () => Promise<{ nonce: string; deviceData: string; details: any }>;
  isAvailable: boolean;
}

const PaypalForm = forwardRef<PaypalFormRef, PaypalFormProps>(
  (
    { onSuccess, onError, amount, merchantName = MERCHANT_NAME_CONSTANT },
    ref
  ) => {
    const paypalCheckoutInstanceRef = useRef<PayPalCheckout | null>(null);
    const deviceDataCollectorRef = useRef<braintree.DataCollector | null>(null);
    const buttonContainerId = useRef(
      `paypal-button-${Math.random().toString(36).slice(2)}`
    );

    const setupBraintree = async () => {
      try {
        const clientInstance = await braintree.client.create({
          authorization: BRAINTREE_AUTH_TOKEN || "",
        });

        paypalCheckoutInstanceRef.current =
          await braintree.paypalCheckout.create({
            client: clientInstance,
          });

        deviceDataCollectorRef.current = await braintree.dataCollector.create({
          client: clientInstance,
        });

        await paypalCheckoutInstanceRef.current.loadPayPalSDK({
          vault: true,
        });

        return true;
      } catch (error) {
        console.error("Error setting up Braintree:", error);
        onError?.(error as Error);
        return false;
      }
    };

    const createPayPalButton = async () => {
      if (!paypalCheckoutInstanceRef.current) {
        const success = await setupBraintree();
        if (!success) return;
      }

      const button = window.paypal.Buttons({
        fundingSource: window.paypal.FUNDING.PAYPAL,
        createBillingAgreement: () => {
          return paypalCheckoutInstanceRef.current!.createPayment({
            flow: "vault",
            intent: "authorize",
            amount: amount.toString(),
            currency: "USD",
            displayName: merchantName,
          } as any);
        },
        onApprove: async (data: any) => {
          try {
            const payload =
              await paypalCheckoutInstanceRef.current!.tokenizePayment(data);
            const deviceData = deviceDataCollectorRef.current?.deviceData || "";
            onSuccess(payload.nonce, deviceData, payload);
            return { nonce: payload.nonce, deviceData, details: payload };
          } catch (error) {
            onError?.(error as Error);
            throw error;
          }
        },
        onCancel: () => {
          console.log("PayPal payment was cancelled");
        },
        onError: (err: Error) => {
          console.error("PayPal error", err);
          onError?.(err);
        },
      });

      if (button.isEligible()) {
        await button.render(`#${buttonContainerId.current}`);
      }
    };

    const tokenize = async () => {
      try {
        if (!paypalCheckoutInstanceRef.current) {
          const success = await setupBraintree();
          if (!success) {
            throw new Error("Failed to initialize PayPal");
          }
        }

        return new Promise<{ nonce: string; deviceData: string; details: any }>(
          async (resolve, reject) => {
            try {
              const button = window.paypal.Buttons({
                fundingSource: window.paypal.FUNDING.PAYPAL,
                createBillingAgreement: () => {
                  return paypalCheckoutInstanceRef.current!.createPayment({
                    flow: "vault",
                    intent: "authorize",
                    amount: amount.toString(),
                    currency: "USD",
                    displayName: merchantName,
                  } as any);
                },
                onApprove: async (data: any) => {
                  const payload =
                    await paypalCheckoutInstanceRef.current!.tokenizePayment(
                      data
                    );
                  const deviceData =
                    deviceDataCollectorRef.current?.deviceData || "";
                  onSuccess(payload.nonce, deviceData, payload);
                  resolve({
                    nonce: payload.nonce,
                    deviceData,
                    details: payload,
                  });
                },
                onCancel: () => {
                  reject(new Error("PayPal payment was cancelled"));
                },
                onError: (err: Error) => {
                  onError?.(err);
                  reject(err);
                },
              });

              await button.render(`#${buttonContainerId.current}`);
            } catch (error) {
              reject(error);
            }
          }
        );
      } catch (error) {
        onError?.(error as Error);
        throw error;
      }
    };

    useImperativeHandle(ref, () => ({
      tokenize,
      isAvailable: true,
    }));

    useEffect(() => {
      createPayPalButton();
      // No cleanup needed as PayPal handles its own cleanup
    }, []); // Empty dependency array to run only once

    return <div id={buttonContainerId.current} style={{ width: "100%" }} />;
  }
);

export default PaypalForm;
