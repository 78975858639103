import { useState, useRef } from "react";
import { BraintreeHostedFieldsFormRef } from "../components/common/BraintreeHostedFieldsForm";

export const useBraintreeForm = () => {
  const [isPaymentValid, setIsPaymentValid] = useState(false);
  const braintreeFormRef = useRef<BraintreeHostedFieldsFormRef>(null);

  const handleValidityChange = (isValid: boolean) => {
    setIsPaymentValid(isValid);
  };

  return {
    isPaymentValid,
    braintreeFormRef,
    handleValidityChange,
  };
};
