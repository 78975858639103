import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Typography,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  TableBody,
  CircularProgress,
  Popover,
  IconButton,
  Divider,
} from "@mui/material";

import { Icon } from "@iconify/react";

import { useCustomerData } from "../../hooks/useApiData";
import { priceCheckPreflight } from "../../api/selfcareApiServices";
import { PriceCheckResponse } from "../../types/interface";

import { formatUTCToMMDDYYYY } from "../../utils/utils";

import {
  TODAY_CHARGE_INFO,
  NEXT_BILLING_DATE_INFO,
} from "../../const/popover-info.const";

interface ChangePlanPriceCheckSummaryProps {
  selectedProducts: any;
}

const ChangePlanPriceCheckSummary: React.FC<
  ChangePlanPriceCheckSummaryProps
> = ({ selectedProducts }) => {
  const { data: customerDataQuery, isLoading } = useCustomerData();
  const [isLoadingPriceCheck, setIsLoadingPriceCheck] = useState(false);
  const [priceCheckData, setPriceCheckData] =
    useState<PriceCheckResponse | null>(null);
  const previousProductCodeRef = useRef<string | null>(null);
  const [popoverStates, setPopoverStates] = useState<{
    [key: string]: HTMLElement | null;
  }>({
    todaysCharge: null,
    nextBill: null,
  });

  useEffect(() => {
    const fetchPriceCheck = async () => {
      // do pricecheck useState only if selectedProducts have change
      if (
        !selectedProducts?.product_code ||
        previousProductCodeRef.current === selectedProducts.product_code
      ) {
        return;
      }

      previousProductCodeRef.current = selectedProducts.product_code;
      setIsLoadingPriceCheck(true);

      try {
        // Get the selected internet product code
        const internetProductCode = selectedProducts.product_code;

        // Get additional device product codes if they exist
        const additionalDeviceCodes =
          selectedProducts.additional_devices?.[0]?.product_code
            ?.split(",")
            ?.map((code: string) => code.trim()) || [];

        // Combine all product codes
        const allProductCodes = [internetProductCode, ...additionalDeviceCodes];

        const response = await priceCheckPreflight({
          product_codes: allProductCodes,
        });
        setPriceCheckData(response.data);
      } catch (error) {
        console.error("Error fetching price check:", error);
      } finally {
        setIsLoadingPriceCheck(false);
      }
    };

    fetchPriceCheck();
  }, [selectedProducts]);

  const handlePopoverOpen =
    (id: string) => (event: React.MouseEvent<HTMLElement>) => {
      setPopoverStates((prev) => ({
        ...prev,
        [id]: event.currentTarget,
      }));
    };

  const handlePopoverClose = (id: string) => () => {
    setPopoverStates((prev) => ({
      ...prev,
      [id]: null,
    }));
  };

  return (
    <Box sx={{ my: 3 }}>
      <Typography
        variant="h6"
        sx={{ fontFamily: "Figtree-Bold", fontSize: 17, mb: 1 }}
      >
        {" "}
        Billing Summary
      </Typography>
      <TableContainer>
        <Table sx={{ "& td, & th": { border: 0 } }}>
          <TableHead></TableHead>
          {isLoadingPriceCheck ? (
            <TableBody>
              <TableRow>
                <TableCell
                  colSpan={2}
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "50px",
                  }}
                >
                  <CircularProgress />
                </TableCell>
              </TableRow>
            </TableBody>
          ) : (
            <TableBody>
              <TableRow>
                <TableCell sx={{ py: 0.5 }}>
                  <Typography variant="body1" color="text.secondary">
                    Today's Charge{" "}
                    <IconButton
                      onClick={handlePopoverOpen("todaysCharge")}
                      size="small"
                      sx={{ padding: 0.5 }}
                    >
                      <Icon icon="bi:info-circle" />
                    </IconButton>
                    <Popover
                      open={Boolean(popoverStates.todaysCharge)}
                      anchorEl={popoverStates.todaysCharge}
                      onClose={handlePopoverClose("todaysCharge")}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Typography sx={{ p: 2, maxWidth: 300 }}>
                        {TODAY_CHARGE_INFO}
                      </Typography>
                    </Popover>
                  </Typography>
                </TableCell>
                <TableCell sx={{ py: 0.5, textAlign: "right" }}>
                  <Typography variant="body1" sx={{ color: "secondary.main" }}>
                    ${priceCheckData?.preflight?.charge_today.toFixed(2)}
                  </Typography>
                </TableCell>
              </TableRow>
              {selectedProducts?.selected_duration === "monthly" && (
                <TableRow>
                  <TableCell sx={{ py: 0.5 }}>
                    <Typography variant="body1" color="text.secondary">
                      Your Next Billing on{" "}
                      {priceCheckData?.preflight?.next_billing_date &&
                        formatUTCToMMDDYYYY(
                          priceCheckData.preflight.next_billing_date
                        )}
                      <IconButton
                        onClick={handlePopoverOpen("nextBill")}
                        size="small"
                        sx={{ padding: 0.5 }}
                      >
                        <Icon icon="bi:info-circle" />
                      </IconButton>
                      <Popover
                        open={Boolean(popoverStates.nextBill)}
                        anchorEl={popoverStates.nextBill}
                        onClose={handlePopoverClose("nextBill")}
                        anchorOrigin={{
                          vertical: "bottom",
                          horizontal: "left",
                        }}
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "left",
                        }}
                      >
                        <Typography sx={{ p: 2, maxWidth: 300 }}>
                          {NEXT_BILLING_DATE_INFO}
                        </Typography>
                      </Popover>
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ py: 0.5, textAlign: "right" }}>
                    <Typography variant="body1">
                      ${priceCheckData?.preflight?.new_charge}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
        <Divider sx={{ my: 2 }} />

        {!selectedProducts.additional_devices && (
          <Typography variant="body1" sx={{ mt: 4, mx: 2 }}>
            1 Day and 1 Week are 1-time purchases. Monthly is recurring.
          </Typography>
        )}
      </TableContainer>

      {/* <pre>{JSON.stringify(priceCheckData, null, 2)} </pre>
      <pre>{JSON.stringify(selectedProducts, null, 2)} </pre>
      <hr /> */}
      {/* <pre>{JSON.stringify(customerDataQuery?.data, null, 2)} </pre>  */}
    </Box>
  );
};

export default ChangePlanPriceCheckSummary;
