import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  CircularProgress,
} from "@mui/material";
import BoingoLogoRed from "../../assets/images/boingo_logo_red.svg";

interface SignupDialogProps {
  open: boolean;
  onClose: () => void;
  dialogMessage: string;
  isSubmitting: boolean;
}

const SignupDialog: React.FC<SignupDialogProps> = ({
  open,
  onClose,
  dialogMessage,
  isSubmitting,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          maxWidth: "400px",
          width: "100%",
        },
      }}
      data-test-id="signup-dialog"
    >
      <DialogTitle
        sx={{ marginTop: 2, fontFamily: "Museo700", textAlign: "center" }}
        data-test-id="signup-dialog-title"
      >
        <img
          src={BoingoLogoRed}
          alt="Boingo"
          height="70"
          data-test-id="signup-dialog-logo"
        />
      </DialogTitle>
      <DialogContent
        sx={{ textAlign: "center" }}
        data-test-id="signup-dialog-content"
      >
        {isSubmitting && (
          <CircularProgress
            sx={{ my: 3, color: "#d52b1e" }}
            data-test-id="signup-dialog-loading"
          />
        )}

        <Typography
          variant="body1"
          sx={{
            fontSize: "16px",
            fontFamily: "Figtree-Bold",
            color:
              dialogMessage.toLowerCase().includes("error") ||
              dialogMessage.toLowerCase().includes("failed") ||
              dialogMessage.toLowerCase().includes("invalid") ||
              dialogMessage.toLowerCase().includes("missing")
                ? "#d52b1e"
                : "#333",
          }}
          data-test-id="signup-dialog-message"
        >
          {dialogMessage}
        </Typography>
      </DialogContent>
      <DialogActions data-test-id="signup-dialog-actions"></DialogActions>
    </Dialog>
  );
};

export default SignupDialog;
