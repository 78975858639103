import React, { useState, useEffect, useCallback, useRef } from "react";
import { Box, Typography, useTheme, useMediaQuery } from "@mui/material";
import Breadcrumbs from "../BroadbandComponents/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import TableSummary from "../BroadbandComponents/TableSummary";
import DurationDetails from "../BroadbandComponents/DurationDetails";
import BroadbandSignupForm from "../BroadbandComponents/BroadbandSignupForm";
import ChangePlanPaymentInfo from "../../selfcare/pages/change-plan/ChangePlanPaymentInfo";
import { Link as RouterLink } from "react-router-dom";
import { useQueryString } from "../../../../hooks/useQueryString";
import { BROADBAND_SPA_PATHS } from "../../../../assets/const/broadband-breadcrumb-paths.const";

//Selfcare Change Plan Components
import ChangePlanPriceCheckSummary from "../../selfcare/pages/change-plan/ChangePlanPriceCheckSummary";

import styles from "./BroadbandCheckoutPage.module.css";

interface CheckoutPageProps {
  data: {
    venue_details: any;
  };
  isChangePlan?: boolean;
}

export interface Device {
  device_name: string;
  device_detail_name: string;
  device_price: string;
  product_code: string;
}

export interface Product {
  plan_name: string;
  product_code: string;
  speed: string;
  speed_unit: string;
  original_price: string;
  discount?: {
    discounted_price: string;
    amount_off: string;
    discount_duration: string;
    promo_code: string;
    promo_details: string;
  };
  duration_details?: {
    [key: string]: {
      price?: string;
      product_code?: string;
      duration_note?: string;
    };
  };
  additional_devices?: Device[];
  selected_duration?: string;
  isAetcDeal?: boolean;
}

const BroadbandCheckoutPage: React.FC<CheckoutPageProps> = ({
  data,
  isChangePlan = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { getFullQueryString } = useQueryString();
  const queryString = getFullQueryString();

  const location = useLocation();
  const navigate = useNavigate();
  const choosePlanPath = location.pathname.split("/").slice(0, -1).join("/");

  const { selectedProduct: initialSelectedProduct } =
    (location.state as { selectedProduct?: Product }) || {};

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(() => {
    if (initialSelectedProduct) {
      const firstDuration = Object.keys(
        initialSelectedProduct?.duration_details || {}
      )[0];
      return {
        ...initialSelectedProduct,
        selected_duration: firstDuration,
      };
    }
    return null;
  });

  const handleBreadcrumbClick = (path: string) => {
    if (path.endsWith(`/${BROADBAND_SPA_PATHS.ADDITIONAL_DEVICES}`)) {
      navigate(path, { state: { selectedProduct: initialSelectedProduct } });
    } else {
      navigate(path, { state: { selectedProduct } });
    }
  };

  const handleDurationSelect = useCallback((duration: string) => {
    setSelectedProduct((prevProduct) => {
      if (!prevProduct) return null;
      const selectedDurationDetails = prevProduct.duration_details?.[duration];
      return {
        ...prevProduct,
        product_code: selectedDurationDetails?.product_code || "",
        original_price: selectedDurationDetails?.price || "",
        selected_duration: duration,
      };
    });
  }, []);

  const hasAdditionalDevices =
    selectedProduct?.additional_devices &&
    selectedProduct.additional_devices.length > 0;

  const tableSummaryRef = useRef<HTMLDivElement>(null);
  const [isSticky, setIsSticky] = useState(false);

  const [totalPrice, setTotalPrice] = useState(0);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (tableSummaryRef.current) {
        const rect = tableSummaryRef.current.getBoundingClientRect();
        setIsSticky(rect.top <= 100); // 20px from the top of the viewport
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const isFreeProduct = selectedProduct?.plan_name.toLowerCase() === "free";

  const breadcrumbSteps = [
    <RouterLink
      to={`${choosePlanPath}${queryString}`}
      onClick={(e) => {
        e.preventDefault();
        handleBreadcrumbClick(`${choosePlanPath}${queryString}`);
      }}
    >
      Choose a Plan
    </RouterLink>,
    ...(!isFreeProduct
      ? [
          <RouterLink
            to={`${choosePlanPath}/${BROADBAND_SPA_PATHS.ADDITIONAL_DEVICES}${queryString}`}
            onClick={(e) => {
              e.preventDefault();
              handleBreadcrumbClick(
                `${choosePlanPath}/${BROADBAND_SPA_PATHS.ADDITIONAL_DEVICES}${queryString}`
              );
            }}
          >
            Options
          </RouterLink>,
        ]
      : []),
    "Checkout",
  ];

  return (
    <Box className={styles.checkoutPageContainer}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: 0,
          minHeight: "100vh",
          backgroundColor: "#f5f5f5",
        }}
      >
        <Box
          sx={{
            maxWidth: "1050px",
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            gap: isMobile ? 2 : 3,
            padding: isMobile ? "16px" : "16px 0 0 16px",
          }}
        >
          {isMobile && selectedProduct && (
            <Box
              ref={tableSummaryRef}
              className={styles.tableSummaryContainer}
              sx={{
                width: "100%",
                px: 0,
              }}
            >
              <TableSummary
                data={data.venue_details}
                selectedProduct={selectedProduct}
                isCheckoutPage={true}
                onTotalPriceChange={setTotalPrice}
              />
            </Box>
          )}
          <Box
            sx={{
              maxWidth: isMobile ? "100%" : "50%",
              py: 2,
              px: isMobile ? 0 : 2,
              margin: 0,
              marginRight: "auto",
            }}
          >
            <Breadcrumbs
              steps={breadcrumbSteps}
              activeStep={isFreeProduct ? 1 : 2}
            />

            <Box className={styles.checkoutWrapper} sx={{ width: "100%" }}>
              {isChangePlan && (
                <ChangePlanPriceCheckSummary
                  selectedProducts={selectedProduct}
                />
              )}
              {/* Hide duration details if isAetcDeal is true */}
              {!selectedProduct?.isAetcDeal && (
                <Box
                  className={styles.durationContainer}
                  sx={{ marginBottom: 3 }}
                >
                  {selectedProduct && (
                    <DurationDetails
                      durationDetails={selectedProduct?.duration_details || {}}
                      discount={selectedProduct?.discount}
                      onDurationSelect={handleDurationSelect}
                      hasAdditionalDevices={!!hasAdditionalDevices}
                      initialDuration={selectedProduct?.selected_duration}
                    />
                  )}
                </Box>
              )}

              {!isChangePlan && (
                <Box sx={{}}>
                  <BroadbandSignupForm
                    data={data.venue_details}
                    selectedProduct={selectedProduct}
                    totalPrice={totalPrice}
                  />
                </Box>
              )}

              {isChangePlan && (
                <ChangePlanPaymentInfo selectedProducts={selectedProduct} />
              )}
            </Box>
          </Box>
          {!isMobile && selectedProduct && (
            <Box
              sx={{
                width: "350px",
                flexShrink: 0,
                position: "sticky",
                top: "30px",
                alignSelf: "flex-start",
                py: 2,
              }}
            >
              <TableSummary
                data={data.venue_details}
                selectedProduct={selectedProduct}
                isCheckoutPage={true}
                onTotalPriceChange={setTotalPrice}
              />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default BroadbandCheckoutPage;
