import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  getCustomerData,
  getDeviceData,
  getChargesData,
  updateCustomerData,
  updateDeviceData,
  updateChargesData,
  addDevice,
  removeDevice,
  getVenueDetailsFromCloudStorage,
  getCustomerMessage,
  inactivateCustomerAccount,
  removeDeferredActions,
  saveOfferOneMonth,
  addFutureProduct,
} from "../api/selfcareApiServices";
import { ChargesDataType, DeviceType } from "../types/interface";

/*
 * Customer calls
 */
export const useCustomerData = () => {
  return useQuery({
    queryKey: ["customerData"],
    queryFn: getCustomerData,
  });
};

export const useCustomerMessage = () => {
  return useQuery({
    queryKey: ["customerMessage"],
    queryFn: getCustomerMessage,
  });
};

export const useUpdateCustomerData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateCustomerData,
    onSuccess: async (response) => {
      // Invalidate and refetch both queries
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ["customerData"] }),
        queryClient.invalidateQueries({ queryKey: ["customerMessage"] }),
      ]);

      // Force a refetch of both queries
      await Promise.all([
        queryClient.refetchQueries({ queryKey: ["customerData"] }),
        queryClient.refetchQueries({ queryKey: ["customerMessage"] }),
      ]);
    },
  });
};

export const useSaveOfferOneMonth = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: saveOfferOneMonth,
  });
};

export const useInactivateCustomerAccount = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: inactivateCustomerAccount,
    onSuccess: (response) => {
      queryClient.invalidateQueries({ queryKey: ["customerData"] });
      queryClient.invalidateQueries({ queryKey: ["customerMessage"] });
    },
  });
};

export const useRemoveDeferredActions = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: removeDeferredActions,
    onSuccess: (response) => {
      // Mark both queries as stale
      queryClient.invalidateQueries({ queryKey: ["customerData"] });
      queryClient.invalidateQueries({ queryKey: ["customerMessage"] });
    },
  });
};

/*
 * Venue Details calls
 */
export const useVenueDetailsFromCloudStorage = () => {
  return useQuery({
    queryKey: ["venueDetails"],
    queryFn: getVenueDetailsFromCloudStorage,
  });
};

/*
 * Device calls
 */
export const useDeviceData = () => {
  return useQuery({
    queryKey: ["deviceData"],
    queryFn: getDeviceData,
  });
};

export const useAddDevice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: addDevice,
    onSuccess: () => {
      // Invalidate and refetch devices query after successful addition
      queryClient.invalidateQueries({ queryKey: ["deviceData"] });
    },
  });
};

export const useUpdateDeviceData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateDeviceData,
    onSuccess: (data: DeviceType) => {
      queryClient.invalidateQueries({ queryKey: ["deviceData"] });
    },
  });
};

export const useRemoveDevice = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: removeDevice,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["deviceData"] });
    },
  });
};

/*
 * Charges calls
 */
export const useChargesData = () => {
  return useQuery({
    queryKey: ["chargesData"],
    queryFn: getChargesData,
  });
};

export const useUpdateChargesData = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateChargesData,
    onSuccess: (data: ChargesDataType) => {
      queryClient.setQueryData(["chargesData"], data);
    },
  });
};

export const useAddFutureProduct = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addFutureProduct,
    onSuccess: async () => {
      // Invalidate and refetch both queries to ensure latest state
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: ["customerData"] }),
        queryClient.invalidateQueries({ queryKey: ["customerMessage"] }),
      ]);
    },
  });
};
