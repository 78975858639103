import * as UAParser from "ua-parser-js";

export const getBrowserAndOS = () => {
  const parser = new UAParser.UAParser();
  const browser = parser.getBrowser();
  const os = parser.getOS();

  return {
    browser: browser.name || "Unknown",
    browserVersion: browser.version || "Unknown",
    os: os.name || "Unknown",
  };
};
