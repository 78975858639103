// csrfService, is independent of axiosInstance, and use to fetch the csrf token for POST, PUT, PATCH, DELETE requests
// TODO: combine selfcare api services with global api services

import axios from "axios";
import { SELFCARE_API_PATH } from "../../../../config/configs";

interface CsrfTokenResponse {
  csrfToken: string;
}

const csrfApiService = axios.create({
  withCredentials: true,
  baseURL: SELFCARE_API_PATH,
});

let csrfToken: CsrfTokenResponse | null = null;

export const getCsrfToken = async () => {
  if (csrfToken) {
    return csrfToken;
  }

  try {
    const response = await csrfApiService.get("/csurf/token");
    csrfToken = response.data.csrfToken;
    return csrfToken;
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
    throw error;
  }
};
