import React from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import DeviceSelector from "./DeviceSelector";
import BroadbandDevicesIcon from "../images/broadband-devices-icons.svg";

interface Device {
  device_name?: string;
  device_detail_name?: string;
  device_price?: string;
  product_code?: string;
}

interface AdditionalDevicesProps {
  additionalDevices: Device[];
  selectedDevice: Device | null;
  onDeviceSelect: (device: Device | null) => void;
  onContinue: () => void;
}

const AdditionalDevices: React.FC<AdditionalDevicesProps> = ({
  additionalDevices,
  selectedDevice,
  onDeviceSelect,
  onContinue,
}) => {
  const isDeviceSelected = selectedDevice !== null;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Box
      className="additionalDevicesContainer"
      data-test-id="additional-devices-container"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
        width: isMobile ? "100%" : "85%",
      }}
    >
      <Box sx={{}}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <img
            src={BroadbandDevicesIcon}
            alt="Broadband Devices"
            data-test-id="broadband-devices-icon"
            width={isMobile ? "50%" : "275px"}
          />
          <Typography
            variant="h5"
            data-test-id="add-devices-header"
            sx={{
              marginBottom: "20px",
              fontSize: isMobile ? "22px" : "26px",
              color: "#545861",
            }}
          >
            Would you like to add more devices?
          </Typography>
        </Box>

        <Typography
          variant="body1"
          data-test-id="devices-description"
          sx={{ marginBottom: "20px", width: isMobile ? "100%" : "80%" }}
        >
          Your account includes three (3) devices. You can add up to two (2)
          more for $10/month each.
        </Typography>
        <DeviceSelector
          additionalDevices={additionalDevices}
          onDeviceSelect={onDeviceSelect}
          selectedDevice={selectedDevice}
          data-test-id="device-selector"
        />
        <Typography
          variant="body2"
          data-test-id="device-note"
          sx={{
            marginTop: "20px",
            fontSize: "16px",
            color: "#545861",
            width: isMobile ? "100%" : "80%",
          }}
        >
          Note: If you add additional devices, you will not be able to choose a
          1-day or 1-week plan.
        </Typography>

        <Button
          variant={isDeviceSelected ? "contained" : "outlined"}
          data-test-id={
            isDeviceSelected ? "continue-button" : "no-thanks-button"
          }
          sx={{
            marginTop: "20px",
            border: isDeviceSelected ? "none" : "1px solid #545861",
            backgroundColor: isDeviceSelected ? "#d52b1e" : "transparent",
            color: isDeviceSelected ? "#fff" : "#545861",
            fontSize: "20px",
            paddingX: 4,
            display: "flex",
            flexDirection: "flex-end",
            "&:hover": {
              backgroundColor: isDeviceSelected
                ? "#b4261e"
                : "rgba(84, 88, 97, 0.04)",
            },
            width: isMobile ? "100%" : "auto",
          }}
          onClick={onContinue}
        >
          {isDeviceSelected ? "Continue" : "No Thanks"}
        </Button>
      </Box>
    </Box>
  );
};

export default AdditionalDevices;
