// csrfService, is independent of axiosInstance, and use to fetch the csrf token for POST, PUT, PATCH, DELETE requests
import axios from "axios";
import { PORTAL_API_PATH } from "../config/configs";
const csrfApiService = axios.create({
  withCredentials: true,
  baseURL: PORTAL_API_PATH,
});

let csrfToken: string | null = null;

export const fetchCsrfToken = async () => {
  if (csrfToken) {
    return csrfToken;
  }

  try {
    const response = await csrfApiService.get("/csurf/token");
    csrfToken = response.data.csrfToken;
    return csrfToken;
  } catch (error) {
    console.error("Error fetching CSRF token:", error);
    throw error;
  }
};

export const clearCsrfToken = () => {
  csrfToken = null;
};
