import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { eventApiLogger } from "../../services/apiServices";
import { logSelfcareEventApi } from "../templates/selfcare/api/selfcareApiServices";
import {
  getQueryParam,
  getPageLoadtime,
  generateGUID,
} from "../../utils/utils";
import { getBrowserAndOS } from "../../utils/getUserAgent";

const isSelfcarePath =
  window.location.hostname.includes("selfcare") ||
  window.location.hostname.includes("selfcare-portal");

const getOrCreateGsid = () => {
  // Check cookie first
  const cookieGsid = document.cookie
    .split("; ")
    .find((row) => row.startsWith("web-app-portal-gsid="))
    ?.split("=")[1];

  if (cookieGsid) {
    return cookieGsid;
  }

  // Check query param if gsid exists and use this gsid
  const queryGsid = getQueryParam("gsid");
  if (queryGsid) {
    // Set cookie with 15 days expiry
    document.cookie = `web-app-portal-gsid=${queryGsid}; max-age=${
      60 * 60 * 24 * 15
    }; path=/`;
    return queryGsid;
  }

  // Generate new GSID, if no gsid is found,
  const newGsid = generateGUID();
  document.cookie = `web-app-portal-gsid=${newGsid}; max-age=${
    60 * 60 * 24 * 15
  }; path=/`;
  return newGsid;
};

const logEventApi = (data: object) => {
  const { browser, browserVersion, os } = getBrowserAndOS();

  const eventData = {
    ap_mac: getQueryParam("ap_mac"),
    gsid: getOrCreateGsid(),
    mac: getQueryParam("mac"),
    event: "onLoad",
    browser: browser,
    browser_version: browserVersion,
    event_level: "analytics",
    event_source: "frontend",
    event_type: "page_hit",
    location: window.location.href,
    on_load: getPageLoadtime(),
    os: os,
    page_title: document.title,
    product: "web-app-portal",
    referrer: document.referrer,
    screen_resolution: `${window.screen.width}x${window.screen.height}`,
    user_agent: navigator.userAgent,
    ...data,
  };

  // Determine which API to use based on the current hostname, this is on GCP (dev|qa|prod)
  //localhost will use eventApiLogger since hostname is localhost, remote GCP will use logSelfcareEventApi for only selfcare path
  if (isSelfcarePath) {
    logSelfcareEventApi([eventData])
      .then(() => console.log("Selfcare event created"))
      .catch((error) => console.error("Error logging selfcare event:", error));
  } else {
    eventApiLogger([eventData])
      .then(() => console.log("Portal event created"))
      .catch((error) => console.error("Error logging portal event:", error));
  }
};

const EventAPI: React.FC = () => {
  const location = useLocation();
  const isFirstMount = useRef(true);
  const prevPathRef = useRef(location.pathname);
  const isPageRefresh = useRef(true);

  useEffect(() => {
    if (isFirstMount.current) {
      // Log initial page load
      logEventApi({
        product: isSelfcarePath ? "web-app-portal: selfcare" : "web-app-portal",
      });
      isFirstMount.current = false;
      return;
    }

    // Check if this is a page refresh or actual route change
    if (isPageRefresh.current) {
      isPageRefresh.current = false;
      return;
    }

    // Only log if the path has actually changed (SPA navigation)
    if (prevPathRef.current !== location.pathname) {
      logEventApi({
        event: "routeChange",
        event_type: "route_change",
        path: location.pathname,
        previous_path: prevPathRef.current,
        product: isSelfcarePath ? "web-app-portal: selfcare" : "web-app-portal",
      });
      prevPathRef.current = location.pathname;
    }
  }, [location]);

  // Reset isPageRefresh on unmount/remount
  useEffect(() => {
    return () => {
      isPageRefresh.current = true;
    };
  }, []);

  return null;
};

export default EventAPI;
