import React from "react";
import {
  Box,
  Button,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface Device {
  device_name?: string;
  device_detail_name?: string;
  device_price?: string;
  product_code?: string;
}

interface DeviceSelectorProps {
  additionalDevices?: Device[] | undefined | null;
  onDeviceSelect: (device: Device | null) => void;
  selectedDevice: Device | null;
}

const DeviceSelector: React.FC<DeviceSelectorProps> = ({
  additionalDevices,
  onDeviceSelect,
  selectedDevice,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClick = (deviceName: string) => {
    const device = additionalDevices?.find((d) => d.device_name === deviceName);
    if (device) {
      if (selectedDevice && selectedDevice.device_name === device.device_name) {
        onDeviceSelect(null); // Deselect if clicking the same device
      } else {
        onDeviceSelect(device);
      }
    }
  };

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column" }}
      data-test-id="device-selector-container"
    >
      {additionalDevices?.map((device) => (
        <Button
          key={device.device_name}
          data-test-id={`device-button-${device.device_name?.toLowerCase()}`}
          data-device-name={device.device_name}
          variant="contained"
          onClick={() => handleClick(device?.device_name || "")}
          sx={{
            justifyContent: "flex-start",
            backgroundColor:
              selectedDevice?.device_name === device.device_name
                ? "#d52b1e"
                : "#4a4a4a",
            marginBottom: "10px",
            "&:hover": {
              opacity: "0.8",
            },
            width: isMobile ? "100%" : "80%",
            padding: 0,
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              borderRadius: "10px 0 0 10px",
              backgroundColor:
                selectedDevice?.device_name === device.device_name
                  ? "#b4261e"
                  : "#111",
              color: "#fff",
              fontSize: "40px",
              fontFamily: "Museo900",
              padding: "10px 20px",
            }}
            data-test-id={`device-quantity-${device.device_name?.toLowerCase()}`}
          >
            +{device?.device_name?.split("_")[0]}
          </Typography>
          <Typography
            sx={{
              color: "#fff",
              fontSize: "24px",
              fontFamily: "Figtree-Black",
              marginLeft: "20px",
            }}
            data-test-id={`device-price-${device.device_name?.toLowerCase()}`}
          >
            ${parseFloat(device?.device_price || "0").toFixed(2)}/month
          </Typography>
        </Button>
      ))}
    </Box>
  );
};

export default DeviceSelector;
