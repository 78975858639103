export const MAC_ADDRESS_INFO =
  " This is your device's unique identifier (example AB:CD:01:EF:34:BA). You can usually find it in your device settings menu. We'll take care of the colons, you just enter the alpha numeric digits.";

export const MY_DEVICES_INFO =
  "Your account includes up to 3 devices. If you are a monthly subscriber, you may purchase access for up to 2 additional devices.";

export const TODAY_CHARGE_INFO =
  "This amount reflects your monthly bill, minus any credit applied to your account.";

export const NEXT_BILLING_DATE_INFO =
  " Your card will be charged between 01:00 and 06:00 UTC.  ";
