export const CLOUD_STORAGE_BASE_URL =
  window._env_?.REACT_APP_CLOUD_STORAGE_BASE_URL ||
  process.env.REACT_APP_CLOUD_STORAGE_BASE_URL;

// Add fallback values
if (!CLOUD_STORAGE_BASE_URL) {
  console.error("CLOUD_STORAGE_BASE_URL is not defined");
}

export const PORTAL_WIFI_PATH =
  window._env_?.REACT_APP_PORTAL_WIFI_PATH ||
  process.env.REACT_APP_PORTAL_WIFI_PATH;
export const PORTAL_BROADBAND_PATH =
  window._env_?.REACT_APP_PORTAL_BROADBAND_PATH ||
  process.env.REACT_APP_PORTAL_BROADBAND_PATH;
export const PORTAL_FIBER_PATH =
  window._env_?.REACT_APP_PORTAL_FIBER_PATH ||
  process.env.REACT_APP_PORTAL_FIBER_PATH;
export const PORTAL_ETHERNET_PATH =
  window._env_?.REACT_APP_PORTAL_ETHERNET_PATH ||
  process.env.REACT_APP_PORTAL_ETHERNET_PATH;
export const PORTAL_PRIVATE_SERVICES_PATH =
  window._env_?.REACT_APP_PORTAL_PRIVATE_SERVICES_PATH ||
  process.env.REACT_APP_PORTAL_PRIVATE_SERVICES_PATH;

// Braintree Token
export const BRAINTREE_AUTH_TOKEN =
  window._env_?.REACT_APP_BRAINTREE_TOKEN ||
  process.env.REACT_APP_BRAINTREE_TOKEN;

// API Path for Web Portal
export const PORTAL_API_PATH =
  window._env_?.REACT_APP_PORTAL_API_PATH ||
  process.env.REACT_APP_PORTAL_API_PATH;

// API Path for Selfcare
export const SELFCARE_API_PATH =
  window._env_?.REACT_APP_SELFCARE_API_PATH ||
  process.env.REACT_APP_SELFCARE_API_PATH;
