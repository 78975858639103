// useApplePay.ts
import { useRef, useState } from "react";
import { ApplePayFormRef } from "../components/common/ApplePayForm";
import { UseFormSetValue, FieldValues, Path } from "react-hook-form";

interface FormFields extends FieldValues {
  first_name?: string;
  last_name?: string;
  email?: string;
  payment_braintree?: any;
  country_code?: string;
}

interface UseApplePayProps<T extends FormFields> {
  setValue: UseFormSetValue<T>;
  setDialogMessage: (message: string) => void;
  setOpenDialog: (open: boolean) => void;
}

export const useApplePay = <T extends FormFields>({
  setValue,
  setDialogMessage,
  setOpenDialog,
}: UseApplePayProps<T>) => {
  const [applePaymentNonce, setApplePayNonce] = useState<string | null>(null);
  const [applePayDeviceData, setApplePayDeviceData] = useState<string | null>(
    null
  );
  const [postalCode, setPostalCode] = useState<string>("");
  const applePayFormRef = useRef<ApplePayFormRef>(null);

  const handleApplePaySuccess = (
    nonce: string,
    deviceData: string,
    event: any
  ) => {
    setApplePayNonce(nonce);
    setApplePayDeviceData(deviceData);
    setPostalCode(event.payment.billingContact.postalCode);

    // Prefill form fields
    setValue("first_name" as Path<T>, event.payment.billingContact.givenName);
    setValue("last_name" as Path<T>, event.payment.billingContact.familyName);
    setValue("email" as Path<T>, event.payment.shippingContact.emailAddress);
  };

  const handleApplePayError = (error: Error) => {
    console.error("Apple Pay error:", error);
    setDialogMessage("Apple Pay processing error. Please try again.");
    setOpenDialog(true);
  };

  const handleApplePayClick = async () => {
    if (applePayFormRef.current) {
      try {
        const result = await applePayFormRef.current.tokenize();
        const { nonce, deviceData, event } = result;
        handleApplePaySuccess(nonce, deviceData, event);
      } catch (error) {
        handleApplePayError(error as Error);
      }
    }
  };

  const resetApplePay = () => {
    setApplePayNonce(null);
    setApplePayDeviceData(null);
    setPostalCode("");
  };

  return {
    applePaymentNonce,
    applePayDeviceData,
    postalCode,
    applePayFormRef,
    handleApplePaySuccess,
    handleApplePayError,
    handleApplePayClick,
    resetApplePay,
  };
};
