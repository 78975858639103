import React, { useState, useRef } from "react";
import { useForm, Controller, SubmitHandler } from "react-hook-form";
import {
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Box,
  MenuItem,
  IconButton,
  InputAdornment,
  Link,
  Divider,
  CircularProgress,
  Chip,
} from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import {
  UsernameField,
  PasswordField,
  FirstNameField,
  LastNameField,
  EmailField,
  PhoneNumberField,
} from "../../../common/AuthFields";
import BraintreeHostedFieldsForm, {
  BraintreeHostedFieldsFormRef,
} from "../../../common/BraintreeHostedFieldsForm";

import { Product, Device } from "../pages/BroadbandCheckoutPage";

import { createCustomerService } from "../../../../services/apiServices";

import { Visibility, VisibilityOff } from "@mui/icons-material";
import VisaLogo from "../../../../assets/images/visa_icon.png";
import MastercardLogo from "../../../../assets/images/mc_icon.png";
import AmexLogo from "../../../../assets/images/amex_icon.png";
import DiscoverLogo from "../../../../assets/images/discover_icon.png";
import PaypalLogo from "../../../../assets/images/paypal_logo.svg";

import { useBraintreeForm } from "../../../../hooks/useBraintreeForm";
import { useBoingoForm } from "../../../../hooks/useBoingoForm";
import { MERCHANT_NAME_CONSTANT } from "../../../../assets/const/merchant-name.const";

import ApplePayForm, { ApplePayFormRef } from "../../../common/ApplePayForm";
import { useApplePay } from "../../../../hooks/useApplePay";

import { useAuthentication } from "../../../../hooks/useAuthentication";
import SignupDialog from "../../../common/SignupDialog";
import GetUserOnline from "../../../common/GetUserOnline";
import { formatProductCodes } from "../../../../utils/utils";
import { formatPhoneNumberForApi } from "../../../../utils/phoneNumberFormatter";
import PaypalForm, { PaypalFormRef } from "../../../common/PaypalForm";
import { usePayPal } from "../../../../hooks/usePayPal";

interface BroadbandSignupFormData {
  username: string;
  password: string;
  first_name: string;
  last_name: string;
  email: string;
  country_code: string;
  agreeTerms: boolean;
  payment_braintree?: {
    nonce: string;
    device_data: string;
    payment_type: string;
    country_code: string;
    postal_code?: string;
  };
  save_payment_info?: boolean;
  sms_opt_in?: boolean;
  phone_number: string;
  discount_code?: string;
}

interface VenueDetails {
  venue_id: string;
  configs: {
    mail_message_id: string;
    gloop_id: string;
    scc: string;
  };
}

interface BroadbandSignupFormProps {
  data: VenueDetails;
  selectedProduct: {
    plan_name: string;
    selected_duration?: string;
    product_code: string;
    additional_devices?: string | { product_code: string }[];
    discount?: {
      promo_code: string;
    };
  } | null;
  totalPrice: number;
}

const BroadbandSignupForm: React.FC<BroadbandSignupFormProps> = ({
  data,
  selectedProduct,
  totalPrice,
}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    control,
    handleSubmit,
    errors,
    handleUsernameBlur,
    trigger,
    setValue,
  } = useBoingoForm<BroadbandSignupFormData>();

  const {
    isCustomerCreated,
    customerLoginData,
    dialogMessage,
    handleAuthSuccess,
    handleAuthError,
    initiateAuthentication,
    setDialogMessage,
  } = useAuthentication();

  const { isPaymentValid, braintreeFormRef, handleValidityChange } =
    useBraintreeForm();

  const {
    applePaymentNonce,
    applePayDeviceData,
    postalCode,
    applePayFormRef,
    handleApplePaySuccess,
    handleApplePayError,
    handleApplePayClick,
    resetApplePay,
  } = useApplePay<BroadbandSignupFormData>({
    setValue,
    setDialogMessage,
    setOpenDialog,
  });

  const {
    paypalPaymentNonce,
    paypalDeviceData,
    paypalFormRef,
    handlePayPalSuccess,
    handlePayPalError,
    resetPayPal,
  } = usePayPal<BroadbandSignupFormData>({
    setValue,
    setDialogMessage,
    setOpenDialog,
  });

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const isMonthlyPlan =
    !selectedProduct?.selected_duration ||
    selectedProduct.selected_duration === "monthly";

  const onSubmit: SubmitHandler<BroadbandSignupFormData> = async (formData) => {
    setIsSubmitting(true);
    setOpenDialog(true);
    setDialogMessage("Creating User...");

    try {
      let customerData;
      let paymentData;

      const { agreeTerms, ...customerFormData } = formData;

      const baseCustomerData = {
        ...customerFormData,
        product_codes: formatProductCodes(
          selectedProduct?.product_code || "",
          selectedProduct?.additional_devices as Device[]
        ),
        mail_message_id: data.configs.mail_message_id,
        venue_id: data.configs.gloop_id,
        scc: data.configs.scc,
        save_payment_info:
          selectedProduct?.selected_duration === "monthly"
            ? true
            : formData.save_payment_info,
        sms_opt_in: formData.sms_opt_in,
        phone_number: formatPhoneNumberForApi(formData.phone_number),
      };

      //if free plan is selected, do not include payment data
      if (selectedProduct?.plan_name.toLowerCase() === "free") {
        customerData = baseCustomerData;
      } else {
        if (applePaymentNonce) {
          paymentData = {
            nonce: applePaymentNonce,
            device_data: applePayDeviceData,
            payment_type: "Apple Pay",
            country_code: formData.country_code,
            postal_code: postalCode, //required for apple pay
          };
        } else if (paypalPaymentNonce) {
          paymentData = {
            nonce: paypalPaymentNonce,
            device_data: paypalDeviceData,
            payment_type: "PayPal",
            country_code: formData.country_code,
          };
        } else {
          //if hosted fields is selected
          const { payload, deviceData } =
            await braintreeFormRef.current!.tokenize();

          paymentData = {
            nonce: payload.nonce,
            device_data: deviceData,
            payment_type: "Credit Card",
            country_code: formData.country_code,
          };
        }

        customerData = {
          ...baseCustomerData,
          payment_braintree: paymentData,
        };

        // Add discount_code only if conditions are met
        if (
          selectedProduct?.selected_duration === "monthly" &&
          selectedProduct?.discount?.promo_code
        ) {
          customerData.discount_code = selectedProduct.discount.promo_code;
        }
      }

      // Check if there are no form validation errors
      if (Object.keys(errors).length === 0) {
        try {
          // Call createCustomerService with the prepared data
          const response = await createCustomerService(customerData);
          console.log("Customer creation response:", response);
          setDialogMessage("User created successfully!");
          if (response.status === 201) {
            initiateAuthentication(customerData);
          }
        } catch (error: any) {
          console.error("Error creating customer:", error);
          setDialogMessage(
            `Error creating user. Please try again. ${error.response.data.message}`
          );
          setOpenDialog(true);
        }
      } else {
        console.error("Form validation errors:", errors);
        setDialogMessage("Please correct the form errors and try again.");
        setOpenDialog(true);
      }
    } catch (error: any) {
      console.error("Error processing form:", error);
      setDialogMessage(error.message || "An error occurred. Please try again.");
      setOpenDialog(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        data-test-id="signup-form"
      >
        {selectedProduct?.plan_name.toLowerCase() !== "free" && (
          <Box className="paymentContainer" data-test-id="payment-container">
            <Typography
              variant="h5"
              sx={{ color: "#545861" }}
              gutterBottom
              data-test-id="payment-title"
            >
              Enter Payment
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                mb: 2,
                position: "relative",
              }}
              data-test-id="express-checkout-container"
            >
              <Typography
                variant="body2"
                align="center"
                data-test-id="express-checkout-title"
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "20px",
                  backgroundColor: "#f5f5f5",
                  margin: "auto",
                  position: "absolute",
                  fontSize: "16px",
                  top: "0px",
                }}
              >
                Express Checkout
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  width: "80%",
                  border: "1px solid #ccc",
                  borderRadius: "5px",
                  margin: "auto",
                  marginY: 4,
                  flexDirection: "column",
                  alignItems: "center",
                  px: 2,
                  py: 3,
                }}
              >
                {window.ApplePaySession &&
                  window.ApplePaySession.canMakePayments() && (
                    <>
                      <Button
                        onClick={handleApplePayClick}
                        variant="contained"
                        data-test-id="apple-pay-button"
                        sx={{
                          "-webkit-appearance": "-apple-pay-button",
                          "-apple-pay-button-style": "black",
                          width: "100%",
                          height: "42px",
                          color: "#fff",
                          marginBottom: 2,
                        }}
                      ></Button>
                      <ApplePayForm
                        ref={applePayFormRef}
                        onSuccess={handleApplePaySuccess}
                        onError={handleApplePayError}
                        amount={totalPrice}
                        merchantName={MERCHANT_NAME_CONSTANT}
                      />
                    </>
                  )}
                <PaypalForm
                  ref={paypalFormRef}
                  onSuccess={handlePayPalSuccess}
                  onError={handlePayPalError}
                  amount={totalPrice}
                  merchantName={MERCHANT_NAME_CONSTANT}
                />
              </Box>
            </Box>

            {!applePaymentNonce && !paypalPaymentNonce && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom: 2,
                  }}
                >
                  <Divider sx={{ flexGrow: 1 }} />
                  <Typography variant="body2" sx={{ mx: 2 }}>
                    OR
                  </Typography>
                  <Divider sx={{ flexGrow: 1 }} />
                </Box>
                <Typography variant="h6" sx={{ color: "#545861" }}>
                  Credit Card
                </Typography>
                <Box
                  className="credit-card-logos"
                  data-test-id="credit-card-logos"
                  sx={{
                    display: "flex",
                    justifyContent: "left",
                    marginY: 3,
                    gap: "10px",
                    flexWrap: "nowrap",
                    overflow: "hidden",
                  }}
                >
                  {[VisaLogo, MastercardLogo, AmexLogo, DiscoverLogo].map(
                    (logo, index) => (
                      <img
                        key={index}
                        src={logo}
                        alt={`Credit Card ${index + 1}`}
                        height="40"
                        style={{ objectFit: "contain" }}
                      />
                    )
                  )}
                </Box>
                <BraintreeHostedFieldsForm
                  ref={braintreeFormRef}
                  control={control}
                  onValidityChange={handleValidityChange}
                  data-test-id="braintree-form"
                />
              </>
            )}

            {(applePaymentNonce || paypalPaymentNonce) && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  flexDirection: "column",
                }}
              >
                <Chip
                  icon={<DoneIcon />}
                  label={`${
                    applePaymentNonce ? "Apple Pay" : "PayPal"
                  } is selected`}
                  variant="outlined"
                  color="success"
                  sx={{
                    marginY: 1,
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    marginY: 1,
                    textAlign: "center",
                    cursor: "pointer",
                    color: "primary.main",
                    textDecoration: "underline",
                  }}
                  onClick={() => {
                    if (applePaymentNonce) {
                      resetApplePay();
                    } else {
                      resetPayPal();
                    }
                  }}
                >
                  or select new payment
                </Typography>
              </Box>
            )}

            {/* Conditionally render the savePaymentInfo checkbox */}
            {!isMonthlyPlan && (
              <Controller
                name="save_payment_info"
                control={control}
                defaultValue={false}
                render={({ field }) => (
                  <FormControlLabel
                    sx={{ color: "#545861" }}
                    control={<Checkbox {...field} />}
                    label=" Save this card for future use"
                  />
                )}
              />
            )}
          </Box>
        )}

        <Typography
          variant="h5"
          gutterBottom
          sx={{ mt: 6, color: "#545861" }}
          data-test-id="account-creation-title"
        >
          Create your account
        </Typography>

        <UsernameField
          control={control}
          errors={errors}
          onUsernameBlur={handleUsernameBlur}
          trigger={trigger}
        />

        <PasswordField control={control} errors={errors} />
        <Box sx={{ my: 8 }}></Box>
        <FirstNameField control={control} errors={errors} />
        <LastNameField control={control} errors={errors} />
        <EmailField control={control} errors={errors} />
        <PhoneNumberField control={control} errors={errors} />

        <Controller
          name="sms_opt_in"
          control={control}
          defaultValue={false}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  sx={{
                    alignSelf: "flex-start",
                    marginTop: "0px", // Adjust this value as needed
                  }}
                  data-test-id="sms-opt-in-checkbox"
                />
              }
              label={
                <Typography
                  variant="body2"
                  sx={{
                    color: "#545861",
                    marginTop: "10px", // Adjust this value to match the checkbox
                  }}
                >
                  By checking this box, you consent to receive updates and
                  marketing communications via SMS. You can change your
                  preferences at any time.
                </Typography>
              }
              sx={{
                alignItems: "flex-start",
                marginTop: 2,
              }}
            />
          )}
        />

        <Box sx={{ display: "flex", justifyContent: "flex-start", mt: 4 }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            data-test-id="complete-order-button"
            sx={{
              paddingY: 1.5,
              color: "white",
              fontFamily: "Figtree-Bold",
              fontSize: "22px",
              borderRadius: "6px",
              backgroundColor: "#d32f2f",
              width: { xs: "100%", md: "260px" },
            }}
            disabled={
              (selectedProduct?.plan_name.toLowerCase() === "free"
                ? false
                : !applePaymentNonce &&
                  !isPaymentValid &&
                  !paypalPaymentNonce) ||
              Object.keys(errors).length > 0 ||
              isSubmitting
            }
          >
            {isSubmitting ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Complete Order"
            )}
          </Button>
        </Box>

        <Typography
          variant="body2"
          sx={{ marginTop: 2, marginBottom: 15 }}
          data-test-id="terms-agreement"
        >
          By clicking submit, I agree to the{" "}
          <Link
            sx={{ color: "#d32f2f", textDecoration: "none" }}
            href="https://www.boingo.com/legal/end-user-license-agreement-boingo-customer-agreement/"
            data-test-id="terms-link"
          >
            Boingo End User License Agreement.{" "}
          </Link>
        </Typography>
      </Box>

      <SignupDialog
        open={openDialog}
        onClose={handleCloseDialog}
        dialogMessage={dialogMessage}
        isSubmitting={isSubmitting}
        data-test-id="signup-dialog"
      />
      {isCustomerCreated && customerLoginData && (
        <GetUserOnline
          customerLoginData={customerLoginData}
          onSuccess={handleAuthSuccess}
          onError={handleAuthError}
        />
      )}
    </>
  );
};

export default BroadbandSignupForm;
