import apiService from "./axiosInstance";

/*
 * POST - Create Customer, accepts customer data object
 */
export const createCustomerService = (data: object) => {
  return apiService.post("/customers", data);
};

/*
 * POST - Check preflight username availability
 */
export const preflightUsernameService = (data: object) => {
  return apiService.post("/customers/verify/username", data);
};

/*
 * POST - Discount Code Verification, accets discount code, and product code
 */
export const promoCodeService = (data: object) => {
  return apiService.post("/customers/verify/discount", data);
};

/*
 * POST - Generate Login Credentials based on credentialType
 * Credential Types: mccs, bwpromo, datacapture
 * mccs: it returns username and password; requires ap_mac
 * bwpromo: it returns BWPROMO string; requires venue_id, mac
 * datacapture: it returns BWPROMO string; requires venue_id, ap_mac, mac, FirstName, Email
 * Use for Lounge, Private Services
 */
export const getLoginCredentials = (data: object, credentialType: string) => {
  return apiService.post(`/credentials/${credentialType}`, data);
};

/*
 * POST - Generate Smartzone Login Credentials
 * Requires username, password, ip, mac
 */
export const getSmartzoneLoginCredentials = (data: object) => {
  return apiService.post("/gateway/login/smartzone", data);
};
/*
 * POST - Returns Device List from username/password
 */

export const getDeviceList = (data: object) => {
  return apiService.post("/devices/list", data);
};

/*
 * PUT - Swap User Device MAC Address
 */
export const updateDeviceList = (deviceId: string, deviceMac: object) => {
  return apiService.put(`/devices/${deviceId}`, deviceMac);
};

/*
 * POST - Add Users Device to User and logs them in to the Network
 */
export const addDeviceList = (data: object) => {
  return apiService.post("/devices", data);
};

/*
 * POST - Log to Event API
 */
export const eventApiLogger = (data: object) => {
  return apiService.post("/log/event", data);
};
