import { useRef, useState } from "react";
import { PaypalFormRef } from "../components/common/PaypalForm";
import { UseFormSetValue, FieldValues, Path } from "react-hook-form";

interface FormFields extends FieldValues {
  first_name?: string;
  last_name?: string;
  email?: string;
}

interface UsePayPalProps<T extends FormFields = FormFields> {
  setValue?: UseFormSetValue<T>;
  setDialogMessage?: (message: string) => void;
  setOpenDialog?: (open: boolean) => void;
}

export function usePayPal<T extends FormFields = FormFields>(
  props: UsePayPalProps<T> = {}
) {
  const { setValue, setDialogMessage, setOpenDialog } = props;
  const [paypalPaymentNonce, setPaypalNonce] = useState<string | null>(null);
  const [paypalDeviceData, setPaypalDeviceData] = useState<string | null>(null);
  const paypalFormRef = useRef<PaypalFormRef>(null);

  const handlePayPalSuccess = (
    nonce: string,
    deviceData: string,
    details: any
  ) => {
    setPaypalNonce(nonce);
    setPaypalDeviceData(deviceData);

    // Only set form values if setValue is provided
    if (setValue && details?.details) {
      if (details.details.firstName) {
        setValue("first_name" as Path<T>, details.details.firstName);
      }
      if (details.details.lastName) {
        setValue("last_name" as Path<T>, details.details.lastName);
      }
      if (details.details.email) {
        setValue("email" as Path<T>, details.details.email);
      }
    }
  };

  const handlePayPalError = (error: Error) => {
    console.error("PayPal error:", error);
    setDialogMessage?.("PayPal processing error. Please try again.");
    setOpenDialog?.(true);
  };

  const resetPayPal = () => {
    setPaypalNonce(null);
    setPaypalDeviceData(null);
  };

  return {
    paypalPaymentNonce,
    paypalDeviceData,
    paypalFormRef,
    handlePayPalSuccess,
    handlePayPalError,
    resetPayPal,
  };
}
