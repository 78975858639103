import axiosSelfcareInstance from "./axiosSelfcareInstance";
import {
  CustomerDataType,
  DeviceDataType,
  ChargesDataType,
  DeviceType,
  PriceCheckDataType,
} from "../types/interface";

/*
 * Event API Logger
 * Log events to the event api
 */
export const logSelfcareEventApi = (data: object) => {
  return axiosSelfcareInstance.post("/log/event", data);
};

/*
 * Authenticate User before using other selfcare api services, get csrf token first
 */
export const authenticateUser = async (data: object) => {
  const response = await axiosSelfcareInstance.post(
    "/authentication/login",
    data
  );
  return response;
};

/*
 * Logout User and clear csrf token, to destroy session
 */
export const logoutUser = async () => {
  const response = await axiosSelfcareInstance.post("/authentication/logout");
  return response;
};

/*
 * Customer calls
 */
export const getCustomerData = async () => {
  return axiosSelfcareInstance.get("/customers");
};

export const getCustomerMessage = async () => {
  return axiosSelfcareInstance.get("/customers/messages");
};

/*
 * Venue Details configs from JSON file on cloud storage
 */
export const getVenueDetailsFromCloudStorage = async () => {
  return axiosSelfcareInstance.get("/customers/config");
};

export const updateCustomerData = async (
  data: CustomerDataType
): Promise<CustomerDataType> => {
  return axiosSelfcareInstance.put("/customers", data);
};
export const saveOfferOneMonth = async (
  data: CustomerDataType
): Promise<CustomerDataType> => {
  return axiosSelfcareInstance.put("/customers/save_offer_onemonth", data);
};

export const inactivateCustomerAccount = async (
  data: CustomerDataType
): Promise<CustomerDataType> => {
  return axiosSelfcareInstance.post("/customers/account_inactivation", data);
};

export const removeDeferredActions = async (data: {
  id: string;
}): Promise<any> => {
  return axiosSelfcareInstance.delete("/customers/deferred_actions", { data });
};

/*
 * Device calls
 */
export const getDeviceData = async (): Promise<DeviceDataType> => {
  return axiosSelfcareInstance.get("/customers/Devices");
};

export const addDevice = async (
  deviceData: Partial<DeviceType>
): Promise<any> => {
  return axiosSelfcareInstance.post("/customers/Devices", deviceData);
};

export const removeDevice = async (deviceId: string): Promise<DeviceType> => {
  return axiosSelfcareInstance.delete(`/customers/Devices/${deviceId}`);
};

export const updateDeviceData = async (
  data: DeviceType
): Promise<DeviceType> => {
  return axiosSelfcareInstance.put(
    `/customers/Devices/${data.device_id}`,
    data
  );
};

/*
 * Charges Receipts calls
 */
export const getChargesData = async (): Promise<ChargesDataType> => {
  return axiosSelfcareInstance.get("/customers/SummaryPayments");
};

export const updateChargesData = async (
  data: ChargesDataType
): Promise<ChargesDataType> => {
  return axiosSelfcareInstance.post("/customers/SummaryPayments/", data);
};

export const emailReceipts = (data: { receipts: (string | undefined)[] }) => {
  return axiosSelfcareInstance.post("/customers/receipts", data);
};

export const priceCheckPreflight = async (
  data: PriceCheckDataType
): Promise<any> => {
  return axiosSelfcareInstance.post("/customers/priceCheck", data);
};

export const addFutureProduct = async (
  data: CustomerDataType
): Promise<CustomerDataType> => {
  return axiosSelfcareInstance.post("/customers/add_future_products", data);
};
