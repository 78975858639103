import { BRAINTREE_AUTH_TOKEN } from "../config/configs";
import { Device } from "../components/templates/broadband/pages/BroadbandCheckoutPage";

export const selfcareDomainProd = "https://selfcare.boingohotspot.net/login";
export const selfcareDomainDevQaStg =
  "https://selfcare-qa-stg.boingohotspot.net/login";

/**
 * Splits a paragraph into sentences, removing periods and creating new lines.
 * @param paragraph The input paragraph to process.
 * @returns A string with sentences on separate lines and periods removed.
 */
export function splitParagraphIntoSentences(paragraph: string): string {
  // Split the paragraph into sentences using periods as delimiters
  const sentences = paragraph.split(".");

  // Remove any leading or trailing whitespace from each sentence
  // and filter out any empty sentences
  const trimmedSentences = sentences
    .map((sentence) => sentence.trim())
    .filter((sentence) => sentence.length > 0);

  // Join the sentences with newline characters
  return trimmedSentences.join("<br />");
}

// return selfcare domain
export const getSelfcareUrl = (host: string) => {
  return host.includes(".boingo.com")
    ? selfcareDomainProd
    : selfcareDomainDevQaStg;
};

// Helper function to get base path
export const getBasePathOfSelfcare = () => {
  // Get the path segments
  const pathSegments = window.location.pathname.split("/");
  // Find 'selfcare' in the path if it exists
  const selfcareIndex = pathSegments.findIndex(
    (segment) => segment === "selfcare"
  );

  if (selfcareIndex !== -1) {
    // Return path up to 'selfcare'
    return pathSegments.slice(0, selfcareIndex + 1).join("/");
  }

  return "";
};

//generate random transaction id for signup
export const generateGUID = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

//validate input for Milstar CC number, exp date, cvv, and postal
export const validateInput = (value: string, regex: RegExp): boolean => {
  return regex.test(value);
};

// save to cookie
export const saveToCookie = (info: any) => {
  // Serialize the object and store it in a cookie
  const serializedInfo = JSON.stringify(info);
  document.cookie = `customerData=${serializedInfo};path=/`;
};

// get cookieby name
export const getCookieByName = (name: string) => {
  const match = document.cookie.match(new RegExp("(^| )" + name + "=([^;]+)"));
  if (match) return match[2];
  return null;
};

//delete cookie by name
export const deleteCookieByName = (name: string) => {
  document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:01 GMT;path=/`;
};

//get  query parameters before or after # in URL
export const getQueryParam = (param: string) => {
  const urlParts = window.location.href.split("#");
  let combinedParams = new URLSearchParams();

  // Check if there's a query string before the hash
  if (urlParts[0].includes("?")) {
    const baseQueryString = urlParts[0].split("?")[1];
    const baseParams = new URLSearchParams(baseQueryString);
    baseParams.forEach((value, key) => {
      combinedParams.set(key, value);
    });
  }

  // Check if there's a query string after the hash
  if (urlParts[1] && urlParts[1].includes("?")) {
    const hashQueryString = urlParts[1].split("?")[1];
    const hashParams = new URLSearchParams(hashQueryString);
    hashParams.forEach((value, key) => {
      combinedParams.set(key, value);
    });
  }

  return combinedParams.get(param);
};

// construct URL
export const constructUrl = (
  path: string,
  venueId: string,
  locationSearch: string
) => {
  const newPath = venueId ? `/${venueId}${path}` : path;
  const search = locationSearch || "";
  return `${newPath}${search}`;
};

// get braintree token
export const getBraintreeToken = () => {
  const token = BRAINTREE_AUTH_TOKEN || "";
  if (!token) {
    console.error("Braintree token is not configured");
    return null;
  }
  return token;
};

type ProductCodesFunction = (
  mainProductCode: string,
  additionalDevices?: Device[]
) => string[];

export const formatProductCodes: ProductCodesFunction = (
  mainProductCode,
  additionalDevices = []
) => {
  const productCodes: string[] = [mainProductCode];

  additionalDevices.forEach((device) => {
    const deviceCodes = device.product_code.split(", ");
    productCodes.push(...deviceCodes);
  });

  return productCodes;
};

// Retrieve Object of Internet Products, and return the free internet product object
export const getInternetFreeProduct = (products: any[]): any | undefined => {
  return products.find((product) => product.plan_name.toLowerCase() === "free");
};

// Redirect to welcome page with query params if they exist
export const redirectToWelcomePage = () => {
  const url = new URL(window.location.href);
  const basePath = url.pathname.endsWith("/")
    ? url.pathname
    : `${url.pathname}/`;
  const welcomePath = `${url.origin}${basePath}welcome`;

  window.location.href = url.search
    ? `${welcomePath}/${url.search}`
    : welcomePath;
};

export const getPageLoadtime = () => {
  if (window.performance && window.performance.timing) {
    return (
      window.performance.timing.domContentLoadedEventEnd -
      window.performance.timing.navigationStart
    );
  }
  return 0;
};
