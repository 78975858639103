import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

interface UpdateCustomerModalProps {
  open: boolean;
  onClose: () => void;
  isError: boolean;
  errorMessage?: string;
}

const UpdateCustomerModal: React.FC<UpdateCustomerModalProps> = ({
  open,
  onClose,
  isError,
  errorMessage,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="update-customer-dialog-title"
      maxWidth="lg"
      sx={{
        "& .MuiDialog-paper": {
          width: { xs: "100%", md: "400px", padding: 10 },
        },
      }}
    >
      <DialogTitle
        id="update-customer-dialog-title"
        sx={{ textAlign: "center" }}
      >
        {" "}
        {isError ? (
          <ErrorOutlineIcon color="error" sx={{ fontSize: 48 }} />
        ) : (
          <CheckCircleOutlineIcon color="success" sx={{ fontSize: 48 }} />
        )}
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography variant="h5" sx={{ fontSize: 24 }}>
            {isError ? "Error Updating Profile" : "You're all set!"}
          </Typography>
          <Typography>
            {isError
              ? errorMessage || "An error occurred while updating your profile."
              : "Your account has been  updated."}
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={onClose}
          variant="outlined"
          color="secondary"
          fullWidth
        >
          Done
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCustomerModal;
