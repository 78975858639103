import { useEffect, forwardRef, useImperativeHandle, useCallback } from "react";
import braintree from "braintree-web";
import { BRAINTREE_AUTH_TOKEN } from "../../config/configs";
import { MERCHANT_NAME_CONSTANT } from "../../assets/const/merchant-name.const";

interface ApplePayFormProps {
  onSuccess: (nonce: string, deviceData: string, event: any) => void;
  onError?: (error: Error) => void;
  amount: number;
  merchantName?: string;
}

export interface ApplePayFormRef {
  tokenize: () => Promise<{ nonce: string; deviceData: string; event: any }>;
  isAvailable: boolean;
}

const ApplePayForm = forwardRef<ApplePayFormRef, ApplePayFormProps>(
  (
    { onSuccess, onError, amount, merchantName = MERCHANT_NAME_CONSTANT },
    ref
  ) => {
    let applePayInstance: braintree.ApplePay | null = null;
    let deviceDataCollector: braintree.DataCollector | null = null;

    const isApplePayAvailable =
      window.ApplePaySession && window.ApplePaySession.canMakePayments();

    const handleApplePayTokenize = useCallback(
      async (
        payload: braintree.ApplePayPayload,
        deviceData: string,
        event: any
      ) => {
        try {
          onSuccess(payload.nonce, deviceData, event);
        } catch (error) {
          onError?.(error as Error);
          throw error;
        }
      },
      [onSuccess, onError]
    );

    useEffect(() => {
      if (!isApplePayAvailable) {
        console.log("Apple Pay is not available on this device");
        return;
      }

      const initializeBraintree = async () => {
        try {
          const clientInstance = await braintree.client.create({
            authorization: BRAINTREE_AUTH_TOKEN || "",
          });

          applePayInstance = await braintree.applePay.create({
            client: clientInstance,
          });

          deviceDataCollector = await braintree.dataCollector.create({
            client: clientInstance,
          });
        } catch (error) {
          console.error("Error initializing Braintree:", error);
          onError?.(error as Error);
        }
      };

      initializeBraintree();

      return () => {
        applePayInstance = null;
        deviceDataCollector = null;
      };
    }, [onError, isApplePayAvailable]);

    const performTokenization = async () => {
      if (!applePayInstance || !window.ApplePaySession) {
        throw new Error("Apple Pay is not available");
      }

      try {
        const paymentRequest = applePayInstance.createPaymentRequest({
          total: {
            label: merchantName,
            amount: amount.toString(),
          },
          requiredBillingContactFields: ["postalAddress"],
          requiredShippingContactFields: ["email", "phone", "postalAddress"],
        });

        const session = new window.ApplePaySession(3, paymentRequest);

        session.onvalidatemerchant = async (event: any) => {
          try {
            const merchantValidation =
              await applePayInstance!.performValidation({
                validationURL: event.validationURL,
                displayName: merchantName,
              });
            session.completeMerchantValidation(merchantValidation);
          } catch (err) {
            console.error("Error validating merchant:", err);
            session.abort();
            onError?.(err as Error);
          }
        };

        return new Promise<{ nonce: string; deviceData: string; event: any }>(
          (resolve, reject) => {
            session.onpaymentauthorized = async (event: any) => {
              try {
                const payload = await applePayInstance!.tokenize({
                  token: event.payment.token,
                });
                const deviceData = deviceDataCollector
                  ? deviceDataCollector.deviceData
                  : "";
                await handleApplePayTokenize(payload, deviceData, event);
                session.completePayment(window.ApplePaySession.STATUS_SUCCESS);
                resolve({ nonce: payload.nonce, deviceData, event });
              } catch (err) {
                session.completePayment(window.ApplePaySession.STATUS_FAILURE);
                onError?.(err as Error);
                reject(err);
              }
            };

            session.begin();
          }
        );
      } catch (error) {
        console.error("Error processing Apple Pay payment:", error);
        onError?.(error as Error);
        throw error;
      }
    };

    useImperativeHandle(ref, () => ({
      tokenize: performTokenization,
      isAvailable: isApplePayAvailable,
    }));

    return null;
  }
);

export default ApplePayForm;
