import React, { Suspense } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";

interface LandingPageProps {
  data: {
    venue_details: {
      venue_id: string;
      internet_products: any[];
      change_plan_products?: any[];
      template: {
        internet_sales_mobile: string;
        internet_sales_desktop: string;
      };
    };
  };
  isChangePlan?: boolean;
}

const InternetSalesPage: React.FC<LandingPageProps> = ({
  data,
  isChangePlan = false,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Dynamically import the template component based on the template name
  const MobileTemplate = React.lazy(
    () =>
      import(
        `../BroadbandTemplates/${data.venue_details.template.internet_sales_mobile}`
      )
  );
  const DesktopTemplate = React.lazy(
    () =>
      import(
        `../BroadbandTemplates/${data.venue_details.template.internet_sales_desktop}`
      )
  );

  return (
    <Box data-test-id="internet-sales-page">
      <Suspense fallback={<div data-test-id="sales-page-loading"></div>}>
        {isMobile ? (
          <Box data-test-id="mobile-template-container">
            <MobileTemplate data={data} isChangePlan={isChangePlan} />
          </Box>
        ) : (
          <Box data-test-id="desktop-template-container">
            <DesktopTemplate data={data} isChangePlan={isChangePlan} />
          </Box>
        )}
      </Suspense>
    </Box>
  );
};

export default InternetSalesPage;
