/**
 * Formats a phone number string into XXX-XXX-XXXX format for US phone numbers.
 * Used on Broadband Signup page and Wireless Signup page.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number in XXX-XXX-XXXX format.
 */
export const formatUSPhoneNumber = (value: string): string => {
  // Remove all non-digit characters
  const phoneNumber = value.replace(/\D/g, "");

  if (phoneNumber.length <= 3) {
    return phoneNumber;
  } else if (phoneNumber.length <= 6) {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3)}`;
  } else {
    return `${phoneNumber.slice(0, 3)}-${phoneNumber.slice(
      3,
      6
    )}-${phoneNumber.slice(6, 10)}`;
  }
};

/**
 * Formats a phone number string into 1 xxxxxxxxxx format.
 * Used on selfcare MyInfo page updating Customer call.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number in 1 xxxxxxxxxx format.
 */
export const formatPhoneNumberForApi = (phoneNumber: string): string => {
  // Remove all non-digit characters
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  // Ensure the number has 10 digits
  if (digitsOnly.length !== 10) {
    throw new Error("Phone number must have 10 digits");
  }

  // Add the country code '1' at the beginning
  return `1 ${digitsOnly}`;
};

/**
 * Formats a phone number string into (X) XXX-XXX-XXXX format.
 * Used on selfcare MyInfo page.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number in (X) XXX-XXX-XXXX format.
 */
export const formatPhoneNumberWithCountryCode = (phoneNumber: string) => {
  // Remove all non-digit characters
  const cleaned = phoneNumber.replace(/\D/g, "");

  // Check if the number has 11 digits (including country code)
  if (cleaned.length === 11) {
    return `(${cleaned.slice(0, 1)}) ${cleaned.slice(1, 4)}-${cleaned.slice(
      4,
      7
    )}-${cleaned.slice(7)}`;
  }

  // If it's a 10-digit number (without country code)
  if (cleaned.length === 10) {
    return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(
      6
    )}`;
  }

  // If the number doesn't match expected formats, return the original
  return phoneNumber;
};

/**
 * Formats a phone number string into XXX-XXX-XXXX format.
 * Used on Eternet Fiber Signup page.
 * @param {string} phoneNumber - The phone number to format.
 * @returns {string} The formatted phone number in XXX-XXX-XXXX format.
 */
export const formatPhoneNumberForEternetFiber = (event: any) => {
  var phoneValue = event.replace(/[^\d]*/g, "");
  // remove non-digits
  phoneValue = phoneValue.replace(
    /(\d{1,3})(\d{1,3})?(\d{1,4})?.*/g,
    "$1 $2 $3"
  );
  phoneValue = phoneValue.trim();
  phoneValue = phoneValue.replace(/\s+/g, "-");
  return phoneValue;
};
