import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Paper,
  Radio,
  FormControlLabel,
  RadioGroup,
  useTheme,
  useMediaQuery,
} from "@mui/material";

interface DurationDetail {
  price?: string;
  product_code?: string;
  duration_note?: string;
}

interface DiscountInfo {
  discounted_price: string;
  amount_off: string;
  discount_duration: string;
  promo_code: string;
  promo_details: string;
}

interface DurationDetailsProps {
  durationDetails?: {
    [key: string]: DurationDetail;
  };
  discount?: DiscountInfo;
  onDurationSelect: (duration: string) => void;
  hasAdditionalDevices?: boolean;
  initialDuration?: string;
}

const DurationDetails: React.FC<DurationDetailsProps> = ({
  durationDetails,
  discount,
  onDurationSelect,
  hasAdditionalDevices,
  initialDuration,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedDuration, setSelectedDuration] = useState<string>(
    initialDuration || ""
  );

  useEffect(() => {
    if (initialDuration && initialDuration !== selectedDuration) {
      setSelectedDuration(initialDuration);
    }
  }, [initialDuration]);

  useEffect(() => {
    if (selectedDuration) {
      onDurationSelect(selectedDuration);
    }
  }, [selectedDuration, onDurationSelect]);

  const handleDurationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDuration(event.target.value);
  };

  const filteredDurationDetails = hasAdditionalDevices
    ? Object.entries(durationDetails || {}).filter(
        ([key]) => key !== "one_week" && key !== "one_day"
      )
    : Object.entries(durationDetails || {});

  return (
    <>
      {/* <pre>{JSON.stringify(durationDetails, null, 2)}</pre> */}
      <Paper
        elevation={0}
        data-test-id="duration-details-container"
        sx={{
          margin: "20px 0 0 0",
          background: "none",
        }}
      >
        <RadioGroup
          aria-label="duration"
          name="duration"
          value={selectedDuration}
          onChange={handleDurationChange}
          data-test-id="duration-radio-group"
        >
          {filteredDurationDetails.map(([key, detail]) => {
            const price =
              key === "monthly" && discount && discount.discounted_price
                ? parseFloat(discount.discounted_price)
                : parseFloat(detail.price || "0");

            return (
              <Paper
                key={key}
                elevation={0}
                data-test-id={`duration-option-${key}`}
                sx={{
                  padding: "15px",
                  marginBottom: "15px",
                  border: "1px solid #ccc",
                }}
              >
                <FormControlLabel
                  value={key}
                  control={<Radio sx={{ alignSelf: "flex-start" }} />}
                  data-test-id={`duration-radio-${key}`}
                  label={
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "baseline",
                          marginTop: 1,
                        }}
                        data-test-id={`duration-price-container-${key}`}
                      >
                        <Typography
                          variant="body1"
                          color="#525252"
                          data-test-id={`duration-price-${key}`}
                          sx={{ marginRight: "8px", fontSize: "18px" }}
                        >
                          ${price.toFixed(2)}
                        </Typography>
                        <Typography
                          data-test-id={`duration-note-${key}`}
                          sx={{ fontSize: "14px" }}
                        >
                          ({detail.duration_note})
                        </Typography>
                      </Box>
                      {key === "monthly" &&
                        discount &&
                        discount.promo_details && (
                          <Box
                            sx={{
                              fontSize: "10px",
                              color: "#777",
                              marginTop: "4px",
                            }}
                            data-test-id="promo-details"
                          >
                            <Typography>{discount.promo_details}</Typography>
                          </Box>
                        )}
                    </Box>
                  }
                  sx={{
                    alignItems: "flex-start",
                    margin: 0,
                  }}
                />
              </Paper>
            );
          })}
        </RadioGroup>
      </Paper>
    </>
  );
};

export default DurationDetails;
