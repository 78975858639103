import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box } from "@mui/material";
import { useQueryString } from "../../hooks/useQueryString";
import { ALIVE_CHECK_CONSTANT } from "../../assets/const/portal.const";
import { generateGUID } from "../../utils/utils";

interface GetUserOnlineProps {
  customerLoginData: customerLoginDataProps;
  onSuccess: (data: any) => void;
  onError: (error: string) => void;
}
interface customerLoginDataProps {
  username: string;
  password: string;
  ap_mac?: string;
  client_mac?: string;
  link_login_only?: string;
  login_type?: string;
}

interface ControllerDataProps {
  username: string;
  password: string;
  gsid: string;
  mac?: string;
  link_login_only: string;
}

const GetUserOnline = ({
  customerLoginData,
  onSuccess,
  onError,
}: GetUserOnlineProps) => {
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const { getQueryParams } = useQueryString();
  const queryParams = getQueryParams();

  // Perform an alive check to see if user is already online (on open internet, for example).
  // If user is online, bypass the network controller login.
  const getAliveCheck = async () => {
    try {
      const response = await axios({
        method: "GET",
        url: "https://alive.boingohotspot.net/cgi-bin/alive?1234567",
        timeout: 5000,
      });
      console.log("Alive check response:", response.data);
      return response.data === ALIVE_CHECK_CONSTANT;
    } catch (error) {
      console.log("Alive check error:", error);
      onError(
        (error as Error).message || "An error occurred during alive check"
      );
      return false;
    }
  };

  useEffect(() => {
    const performAuthentication = async () => {
      setIsAuthenticating(true);
      try {
        const isAlive = await getAliveCheck();
        console.log("Is alive:", isAlive);

        if (isAlive) {
          console.log("User is already online, redirecting to welcome");
          //call onSuccess which is handleAuthSuccess with customerLoginData in useAuthentication hook
          onSuccess(customerLoginData);
        } else {
          console.log("User is not online, authenticating...");
          await authenticate();
        }
      } catch (error) {
        console.log("Error during authentication process:", error);
        onError(
          (error as Error).message || "An error occurred during authentication"
        );
      } finally {
        setIsAuthenticating(false);
      }
    };

    if (customerLoginData) {
      performAuthentication();
    }
  }, [customerLoginData, onSuccess, onError]);

  const authenticate = async () => {
    console.log("Authenticate function called");
    try {
      const { username, password } = customerLoginData;

      const controllerData = {
        username,
        password,
        gsid: queryParams.gsid || generateGUID(),
        //client_mac is for smartzone login and wlc login
        mac: queryParams.mac || queryParams.client_mac,
        link_login_only: queryParams.link_login_only || queryParams.switch_url,
      };

      // Check for missing required fields
      const missingFields = Object.entries(controllerData)
        .filter(([key, value]) => !value && key !== "password")
        .map(([key]) => key);

      if (missingFields.length > 0) {
        throw new Error(`Missing required fields: ${missingFields.join(", ")}`);
      }

      await createVirtualFormAndSubmit(controllerData);
    } catch (error) {
      console.log("Error during authentication:", (error as Error).message);
      onError(
        (error as Error).message || "An error occurred during authentication"
      );
    }
  };

  const createVirtualFormAndSubmit = async (
    dataFromController: ControllerDataProps
  ) => {
    // Create a virtual form element
    const form = document.createElement("form");
    form.className = "hiddenForm";
    form.id = "via_login_form";
    form.method = "POST";
    form.style.display = "none";

    // Define the input fields to be included in the form
    const inputs = [
      { id: "username", name: "username" },
      { id: "user", name: "user" },
      { id: "password", name: "password" },
      { id: "cmd", name: "cmd", value: "authenticate" },
      { id: "buttonClicked", name: "buttonClicked", value: "4" },
      { id: "gsid", name: "gsid" },
    ];

    // Create and append input elements to the form
    inputs.forEach((input) => {
      const inputElement = document.createElement("input");
      inputElement.type = "hidden";
      inputElement.style.display = "none";
      inputElement.className = "noshow";
      inputElement.id = input.id;
      inputElement.name = input.name;
      inputElement.value = input.value || "";
      form.appendChild(inputElement);
    });

    // Append the form to the body
    document.body.appendChild(form);

    // Fill in the form data using the provided dataFromController object
    Object.entries(dataFromController).forEach(([key, value]) => {
      if (key === "link_login_only") {
        // Set the form's action attribute
        form.action = value as string;
      } else if (key === "username" || key === "user") {
        // Set both 'username' and 'user' fields to the same value
        (form.querySelector("#username") as HTMLInputElement).value =
          value as string;
        (form.querySelector("#user") as HTMLInputElement).value =
          value as string;
      } else {
        // Find the input with the matching ID and set its value
        const input = form.querySelector("#" + key);
        if (input) {
          (input as HTMLInputElement).value = value as string;
        }
      }
    });

    // Submit the form
    form.submit();
  };

  if (isAuthenticating) {
    return (
      <Box
        sx={{ visibility: "hidden", display: "none" }}
        data-test-id="authenticating-message"
      >
        Authenticating...
      </Box>
    );
  }

  return (
    <Box data-test-id="get-user-online-container">
      <form
        className="hiddenForm"
        id="via_login_form"
        method="POST"
        style={{ display: "none" }}
        data-test-id="virtual-login-form"
      ></form>
    </Box>
  );
};

export default GetUserOnline;
