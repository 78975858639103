import { useForm, SubmitHandler, Path } from "react-hook-form";
import { preflightUsernameService } from "../services/apiServices";

interface FormData {
  [key: string]: any;
}

export const useBoingoForm = <T extends FormData>() => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setError,
    clearErrors,
    watch,
    trigger,
    setValue,
  } = useForm<T>({
    mode: "onChange",
  });

  const handleUsernameBlur = async (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const username = event.target.value;
    if (username) {
      await trigger("username" as Path<T>);

      const currentErrors = errors.username;

      // Only proceed with availability check if there are no regex errors
      if (!currentErrors) {
        try {
          const response = await preflightUsernameService({ username });
          if (response.status === 200) {
            clearErrors("username" as Path<T>);
          }
        } catch (error) {
          console.error("Username is not available:", error);
          setError("username" as Path<T>, {
            type: "manual",
            message: "Username is not available",
          });
        }
      }
    }
  };

  return {
    control,
    handleSubmit: handleSubmit as (
      onSubmit: SubmitHandler<T>
    ) => (e?: React.BaseSyntheticEvent) => Promise<void>,
    errors,
    isValid,
    watch,
    handleUsernameBlur,
    trigger,
    setValue,
  };
};
