import React, { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import EventAPI from "../components/common/EventAPI";

// Shared components loaded eagerly for reuse
import InternetSalesPage from "../components/templates/broadband/pages/InternetSalesPage";
import AdditionalDevicesPage from "../components/templates/broadband/pages/AdditionalDevicesPage";
import BroadbandCheckoutPage from "../components/templates/broadband/pages/BroadbandCheckoutPage";

// Lazy loaded routes
const SelfcareRoute = React.lazy(
  () => import("../components/templates/selfcare/SelfcareRoute")
);
const WifiRoute = React.lazy(
  () => import("../components/templates/wifi/WifiRoute")
);
const BroadbandRoute = React.lazy(
  () => import("../components/templates/broadband/BroadbandRoute")
);
const EthernetRoute = React.lazy(
  () => import("../components/templates/ethernet/EthernetRoute")
);
const FiberRoute = React.lazy(
  () => import("../components/templates/fiber/FiberRoute")
);
const PrivateServicesRoute = React.lazy(
  () => import("../components/templates/private-services/PrivateServicesRoute")
);

// Export shared components for reuse
export { InternetSalesPage, AdditionalDevicesPage, BroadbandCheckoutPage };

const RouteConfig: React.FC = () => {
  // Get the hostname to determine which component to render
  const hostname = window.location.hostname;
  const isLocalhost = hostname === "localhost" || hostname === "127.0.0.1";

  return (
    <>
      <EventAPI />
      <Suspense fallback={<div style={{}}></div>}>
        <Routes>
          {/* Localhost development routes */}
          {isLocalhost && (
            <>
              {/* Selfcare routes without prefix */}
              <Route path="/*" element={<SelfcareRoute />} />

              {/* Other portal routes with their prefixes */}
              <Route path="/wifi/:venue_id/*" element={<WifiRoute />} />
              <Route
                path="/broadband/:venue_id/*"
                element={<BroadbandRoute />}
              />
              <Route
                path="/private-services/:venue_id/*"
                element={<PrivateServicesRoute />}
              />
              <Route path="/ethernet/:venue_id/*" element={<EthernetRoute />} />
              <Route path="/fiber/:venue_id/*" element={<FiberRoute />} />
            </>
          )}

          {/* Production/ GCP routes */}
          {!isLocalhost && (
            <>
              {(hostname.startsWith("wifi") ||
                hostname.startsWith("wifi-portal")) && (
                <Route path="/:venue_id/*" element={<WifiRoute />} />
              )}
              {(hostname.startsWith("broadband") ||
                hostname.startsWith("broadband-portal")) && (
                <Route path="/:venue_id/*" element={<BroadbandRoute />} />
              )}
              {(hostname.startsWith("private-services") ||
                hostname.startsWith("private-services-portal")) && (
                <Route path="/:venue_id/*" element={<PrivateServicesRoute />} />
              )}
              {(hostname.startsWith("ethernet") ||
                hostname.startsWith("ethernet-portal")) && (
                <Route path="/:venue_id/*" element={<EthernetRoute />} />
              )}
              {(hostname.startsWith("fiber") ||
                hostname.startsWith("fiber-portal")) && (
                <Route path="/:venue_id/*" element={<FiberRoute />} />
              )}
              {(hostname.startsWith("selfcare") ||
                hostname.startsWith("selfcare-portal")) && (
                <Route path="/*" element={<SelfcareRoute />} />
              )}
            </>
          )}
        </Routes>
      </Suspense>
    </>
  );
};

export default RouteConfig;
