import React, { useState } from "react";
import {
  Box,
  Typography,
  Link,
  Button,
  CircularProgress,
  Divider,
  Checkbox,
} from "@mui/material";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import AppleIcon from "@mui/icons-material/Apple";
import {
  useCustomerData,
  useUpdateCustomerData,
  useAddFutureProduct,
} from "../hooks/useApiData";
import { SubmitHandler } from "react-hook-form";
import { useBoingoForm } from "../../../../hooks/useBoingoForm";
import { useBraintreeForm } from "../../../../hooks/useBraintreeForm";
import BraintreeHostedFieldsForm from "../../../common/BraintreeHostedFieldsForm";
import PaymentLogos from "../../../../assets/images/payment_logos.png";
import { CustomerDataType, PaymentUpdateProps } from "../types/interface";
import UpdateCustomerModal from "./modals/UpdateCustomerModal";

import { useNavigate } from "react-router-dom";

interface PaymentFormProps {
  payment_braintree: {
    device_data: string;
    nonce: string;
    payment_type: string;
  };
  country_code: string;
}

interface CurrentPaymentMethodFormProps {
  onPaymentUpdate?: () => void;
  showSaveChangesButtons?: boolean;
  showActionButtons?: boolean;
  selectedProducts?: {
    product_code: string;
    additional_devices?: Array<{ product_code: string }>;
  };
  selectedDevices?: string[];
  actionType?: "add_another" | "reactivate_plan";
}

const CurrentPaymentMethodForm: React.FC<CurrentPaymentMethodFormProps> = ({
  onPaymentUpdate,
  showSaveChangesButtons = true,
  showActionButtons = true,
  selectedProducts,
  selectedDevices,
  actionType,
}) => {
  const navigate = useNavigate();
  const { data: customerDataQuery, isLoading } = useCustomerData();
  const updateCustomerMutation = useUpdateCustomerData();
  const addFutureProductMutation = useAddFutureProduct();
  const [showPaymentForm, setShowPaymentForm] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const [updateError, setUpdateError] = useState<string | undefined>();
  const [isCompletingPurchase, setIsCompletingPurchase] = useState(false);
  const [smsOptIn, setSmsOptIn] = useState(false);

  const { control, handleSubmit, errors } = useBoingoForm<PaymentFormProps>();
  const { isPaymentValid, braintreeFormRef, handleValidityChange } =
    useBraintreeForm();

  const handleModalClose = () => {
    setIsUpdateModalOpen(false);
    if (!updateError) {
      setShowPaymentForm(false);
    }
  };

  const handleCompletePurchase = async () => {
    setIsCompletingPurchase(true);
    try {
      let productCodes: string[] = [];

      if (selectedProducts) {
        const internetProductCode = selectedProducts.product_code;
        const additionalDeviceCodes =
          selectedProducts.additional_devices?.[0]?.product_code
            ?.split(",")
            ?.map((code: string) => code.trim()) || [];
        productCodes = [internetProductCode, ...additionalDeviceCodes];
      } else if (customerDataQuery?.data?.product_codes && selectedDevices) {
        productCodes = [
          ...customerDataQuery.data.product_codes,
          ...selectedDevices,
        ];
      }

      const updatedData = {
        product_codes: productCodes,
        sms_opt_in: smsOptIn,
      };

      if (actionType === "add_another") {
        await addFutureProductMutation.mutateAsync(
          updatedData as CustomerDataType
        );
      } else {
        await updateCustomerMutation.mutateAsync(
          updatedData as CustomerDataType
        );
      }

      navigate("/");
    } catch (error: any) {
      console.error("Error in complete purchase:", error);
      setUpdateError(
        error?.response?.data?.message ||
          error?.message ||
          "An error occurred while updating your profile."
      );
      setIsUpdateModalOpen(true);
    } finally {
      setIsCompletingPurchase(false);
      setIsSubmitting(false);
    }
  };

  const onSubmit: SubmitHandler<PaymentFormProps> = async (formData) => {
    if (customerDataQuery?.data) {
      if (isPaymentValid && braintreeFormRef.current) {
        setIsSubmitting(true);

        try {
          const { payload, deviceData } =
            await braintreeFormRef.current.tokenize();

          if (payload && payload.nonce) {
            const updatedData: PaymentUpdateProps = {
              payment_braintree: {
                nonce: payload.nonce,
                device_data: deviceData,
                payment_type: "Credit Card",
                country_code: formData?.country_code,
              },
            };

            updateCustomerMutation.mutate(updatedData as CustomerDataType, {
              onSuccess: () => {
                setUpdateError(undefined);
                setIsUpdateModalOpen(true);
                setIsSubmitting(false);
                if (onPaymentUpdate) {
                  onPaymentUpdate();
                }
              },
              onError: (error: any) => {
                setUpdateError(
                  error?.message ||
                    "An error occurred while updating your profile."
                );
                setIsUpdateModalOpen(true);
                setIsSubmitting(false);
              },
            });
          }
        } catch (error) {
          console.error("Error updating payment method:", error);
          setIsSubmitting(false);
        }
      }
    }
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box className="paymentMethod">
      <Typography
        variant="h6"
        sx={{ fontFamily: "Figtree-Bold", fontSize: 17 }}
      >
        Current Payment Method
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          gap: 1,
          marginY: 2,
        }}
      >
        {customerDataQuery?.data.payment_type === "Apple Pay" ? (
          <AppleIcon sx={{ fontSize: 30 }} />
        ) : (
          <CreditCardIcon sx={{ fontSize: 30 }} />
        )}
        <Typography variant="body1">
          {customerDataQuery?.data.payment_braintree?.credit_card_last_4
            ? `${customerDataQuery?.data.payment_braintree?.payment_type} •••• •••• •••• ${customerDataQuery?.data.payment_braintree?.credit_card_last_4}`
            : customerDataQuery?.data.payment_braintree?.payment_type ===
              "Apple Pay"
            ? "Apple Pay"
            : "None"}
        </Typography>
      </Box>
      <Link
        component="button"
        onClick={() => setShowPaymentForm(!showPaymentForm)}
        sx={{
          color: "secondary.main",
          textDecoration: "none",
          cursor: "pointer",
          mt: 1,
          display: "block",
          "&:hover": {
            textDecoration: "underline",
          },
        }}
      >
        {showPaymentForm ? "Hide Payment Form" : "Edit Payment Details"}
      </Link>

      {showPaymentForm && (
        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ position: "relative" }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mt: 4,
              }}
            >
              <CreditCardIcon sx={{ fontSize: 30, marginRight: 1 }} />
              <Typography
                variant="h6"
                sx={{ fontFamily: "Figtree-Bold", fontSize: 17 }}
              >
                New Payment Method
              </Typography>
            </Box>
            <img
              src={PaymentLogos}
              alt="Payment Logos"
              height={30}
              style={{ marginTop: 8 }}
            />

            <BraintreeHostedFieldsForm
              ref={braintreeFormRef}
              control={control}
              onValidityChange={handleValidityChange}
            />
          </Box>
          {showSaveChangesButtons && (
            <Box sx={{ display: "flex", gap: 2, mt: 2 }}>
              <Button
                disableElevation
                variant="outlined"
                color="secondary"
                onClick={() => setShowPaymentForm(false)}
                sx={{ height: "40px", flex: 1 }}
              >
                Cancel
              </Button>
              <Button
                disableElevation
                type="submit"
                variant="contained"
                color="secondary"
                disabled={isSubmitting}
                sx={{ height: "40px", flex: 1 }}
              >
                {isSubmitting ? (
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    <CircularProgress size={20} color="inherit" />
                  </Box>
                ) : (
                  "Save Changes"
                )}
              </Button>
            </Box>
          )}
        </Box>
      )}

      {!showPaymentForm && showActionButtons && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            mt: 2,
          }}
        >
          <Divider />
          <Box sx={{ display: "flex", alignItems: "flex-start", gap: 1 }}>
            <Checkbox
              sx={{ mt: -0.5 }}
              checked={smsOptIn}
              onChange={(e) => setSmsOptIn(e.target.checked)}
              value="sms"
            />
            <Typography variant="body1" sx={{ flex: 1 }}>
              By checking this box, you consent to receive updates and marketing
              communications via SMS. You can change your preferences at any
              time.
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row" },
              gap: { xs: 0, md: 2 },
            }}
          >
            <Button
              variant="outlined"
              disableElevation
              color="secondary"
              onClick={() => navigate("/")}
              disabled={isCompletingPurchase}
              sx={{
                height: "40px",
                width: { xs: "100%", md: "200px" },
                my: { xs: 0, md: 2 },
                order: { xs: 2, md: 1 },
                "&.Mui-disabled": {
                  backgroundColor: "#ccc",
                  color: "#666",
                },
              }}
            >
              Cancel
            </Button>
            <Button
              disableElevation
              variant="contained"
              color="secondary"
              onClick={handleCompletePurchase}
              disabled={isCompletingPurchase}
              sx={{
                height: "40px",
                width: { xs: "100%", md: "200px" },
                my: 2,
                order: { xs: 1, md: 2 },
                "&.Mui-disabled": {
                  backgroundColor: "#ccc",
                  color: "#666",
                },
              }}
            >
              {isCompletingPurchase ? (
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <CircularProgress size={20} color="inherit" />
                </Box>
              ) : (
                "Complete Purchase"
              )}
            </Button>
          </Box>
        </Box>
      )}

      <UpdateCustomerModal
        open={isUpdateModalOpen}
        onClose={handleModalClose}
        isError={!!updateError}
        errorMessage={updateError}
      />
    </Box>
  );
};

export default CurrentPaymentMethodForm;
