import axios from "axios";
import { SELFCARE_API_PATH } from "../../../../config/configs";
import { getBasePathOfSelfcare } from "../../../../utils/utils";
import { getCsrfToken } from "./csurfServiceSelfcare";

const apiServiceSelfcare = axios.create({
  baseURL: SELFCARE_API_PATH,
  withCredentials: true,
});

// Add a request interceptor
apiServiceSelfcare.interceptors.request.use(
  async (config) => {
    const method = config.method?.toUpperCase();

    if (
      ["POST", "PUT", "PATCH", "DELETE"].includes(method as string) &&
      config.url !== "/csurf/token" &&
      config.url !== "/log/event"
    ) {
      const csrfToken = await getCsrfToken();
      config.headers["X-CSRF-Token"] = csrfToken;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
apiServiceSelfcare.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      // Handle authentication errors (401 Unauthorized or 403 Forbidden)
      if (error.response.status === 401 || error.response.status === 403) {
        // Clear authentication data
        localStorage.removeItem("selfcareAuth");

        // Get the current path
        const currentPath = window.location.pathname;

        // Only redirect if not already on the login page
        if (!currentPath.includes("/login")) {
          // Store the current location for potential redirect after login
          const returnUrl = window.location.pathname + window.location.search;
          sessionStorage.setItem("returnUrl", returnUrl);

          // Get base path and construct login URL
          const basePath = getBasePathOfSelfcare();
          const loginPath = `${basePath}/login`;

          // Redirect to login using relative path
          window.location.href = loginPath;
        }
      }

      console.error("Error response:", {
        status: error.response.status,
        data: error.response.data,
      });
    } else {
      console.error("Error message:", error.message);
    }
    return Promise.reject(error);
  }
);

export default apiServiceSelfcare;
