import React, { ReactNode } from "react";
import { Breadcrumbs as MuiBreadcrumbs, Typography } from "@mui/material";

interface BreadcrumbsProps {
  steps: (string | ReactNode)[];
  activeStep: number;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ steps, activeStep }) => {
  return (
    <MuiBreadcrumbs separator="›" aria-label="breadcrumb">
      {steps.map((step, index) => (
        <Typography
          key={index}
          color={index === activeStep ? "#d52b1e" : "text.secondary"}
        >
          {typeof step === "string"
            ? step
            : React.cloneElement(step as React.ReactElement, {
                style: {
                  color: index === activeStep ? "#d52b1e" : "inherit",
                  textDecoration: "none",
                },
              })}
        </Typography>
      ))}
    </MuiBreadcrumbs>
  );
};

export default Breadcrumbs;
