import { useSearchParams } from "react-router-dom";

/* This hook is used to get the full query string and the query parameters from the search params
 * This is used to pass the query parameters to the next page
 */
export const useQueryString = () => {
  const [searchParams] = useSearchParams();

  const getFullQueryString = () => {
    return searchParams.toString() ? `?${searchParams.toString()}` : "";
  };

  const getQueryParams = () => {
    const params: { [key: string]: string } = {};
    searchParams.forEach((value, key) => {
      params[key] = value;
    });
    return params;
  };

  return {
    getFullQueryString,
    getQueryParams,
  };
};
