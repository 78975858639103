import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link as RouterLink } from "react-router-dom";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import Breadcrumbs from "../BroadbandComponents/Breadcrumbs";
import TableSummary from "../BroadbandComponents/TableSummary";
import AdditionalDevices from "../BroadbandComponents/AdditionalDevices";
import { useQueryString } from "../../../../hooks/useQueryString";

interface Device {
  device_name?: string;
  device_detail_name?: string;
  device_price?: string;
  product_code?: string;
}

interface Product {
  plan_name: string;
  product_code: string;
  speed?: string | undefined | null;
  speed_unit?: string | undefined | null;
  original_price?: string | undefined | null;
  discount?: {
    discounted_price?: string | undefined | null;
    amount_off?: string | undefined | null;
    discount_duration?: string | undefined | null;
    promo_code?: string | undefined | null;
  };
  additional_devices?: Device[];
}

interface VenueDetails {
  path: string;
  page_title: string;
  base_name: string;
  venue_id: string;
  internet_products: Product[];
  additional_devices?: Device[] | undefined | null;
  configs: {
    [key: string]: any;
  };
}

interface AdditionalDevicesPageProps {
  data: {
    venue_details: VenueDetails;
  };
}

const AdditionalDevicesPage: React.FC<AdditionalDevicesPageProps> = ({
  data,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const navigate = useNavigate();
  const { getFullQueryString } = useQueryString();
  const queryString = getFullQueryString();
  const basePath = location.pathname.split("/").slice(0, -1).join("/");
  const separator = queryString ? "&" : "?";
  const choosePlanPath = location.pathname.split("/").slice(0, -1).join("/");

  const { selectedProduct: initialSelectedProduct } =
    (location.state as { selectedProduct?: Product }) || {};

  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const [selectedDevice, setSelectedDevice] = useState<Device | null>(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (initialSelectedProduct) {
      const resetProduct = {
        ...initialSelectedProduct,
        additional_devices: undefined,
      };
      setSelectedProduct(resetProduct);
    }
  }, [initialSelectedProduct]);

  const handleDeviceSelect = (device: Device | null) => {
    setSelectedDevice(device);
    if (selectedProduct) {
      setSelectedProduct({
        ...selectedProduct,
        additional_devices: device
          ? [
              {
                ...device,
                product_code: device.product_code,
              },
            ]
          : undefined,
      });
    }
  };

  const handleContinue = () => {
    if (selectedProduct) {
      navigate(`${basePath}/checkout${queryString}${separator}`, {
        state: { selectedProduct },
      });
    } else {
      console.error("No product selected");
    }
  };

  const handleBreadcrumbClick = (path: string) => {
    navigate(path, { state: { selectedProduct: initialSelectedProduct } });
  };

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f5f5f5",
      }}
      data-test-id="additional-devices-page"
    >
      <Box
        sx={{
          maxWidth: "1050px",
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          gap: isMobile ? 2 : 3,
          padding: isMobile ? "16px" : "16px 0 0 16px",
        }}
        data-test-id="additional-devices-content"
      >
        {/* Mobile Table Summary */}
        {isMobile && selectedProduct && (
          <Box
            sx={{ width: "100%", px: 0 }}
            data-test-id="mobile-table-summary"
          >
            <TableSummary
              data={data.venue_details}
              selectedProduct={selectedProduct}
            />
          </Box>
        )}

        {/* Left Column - Main Content */}
        <Box
          sx={{
            flex: "1 1 auto",
            maxWidth: isMobile ? "100%" : "calc(100% - 400px)",
            py: 2,
            px: isMobile ? 0 : 2,
          }}
          data-test-id="main-content"
        >
          <Breadcrumbs
            steps={[
              <RouterLink
                to={`${choosePlanPath}${queryString}`}
                data-test-id="choose-plan-link"
                onClick={(e) => {
                  e.preventDefault();
                  handleBreadcrumbClick(`${choosePlanPath}${queryString}`);
                }}
              >
                Choose a Plan
              </RouterLink>,
              "Options",
              "Checkout",
            ]}
            activeStep={1}
          />
          <AdditionalDevices
            additionalDevices={data.venue_details?.additional_devices || []}
            selectedDevice={selectedDevice}
            onDeviceSelect={handleDeviceSelect}
            onContinue={handleContinue}
          />
        </Box>

        {/* Desktop Table Summary - Right Column */}
        {!isMobile && selectedProduct && (
          <Box
            sx={{
              width: "350px",
              flexShrink: 0,
              position: "sticky",
              top: "30px",
              alignSelf: "flex-start",
              py: 2,
            }}
            data-test-id="desktop-table-summary"
          >
            <TableSummary
              data={data.venue_details}
              selectedProduct={selectedProduct}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default AdditionalDevicesPage;
