// API Paths that are exempt from CSRF token check
export const EXEMPT_PATHS: string[] = [
  "/csurf/token",
  "/credentials/mccs",
  "/credentials/bwpromo",
  "/credentials/datacapture",
  "/log/event",
];

// Helper function to check if a URL is exempt from CSRF token check
export const isExemptPath = (url: string): boolean => {
  return EXEMPT_PATHS.includes(url);
};
